import React from 'react';
import { withRouter } from 'react-router';

import Modal from '../../components/utility/Modal';

import styled from 'styled-components';
import base from '../../firebase';
import SmallPhoneInput from '../../containers/public/PhoneInput';
import 'react-phone-number-input/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import * as color from '../../color';
import InputText from '../../components/user/Form/InputText';
import Lottie from 'react-lottie';
import animationData from '../../containers/public/Loading.json';
import Success from '../../components/utility/Success';
import PropTypes from 'prop-types';




const LottieAnimation = styled(Lottie)`
  width: 60%;  // Taille appropriée pour l'animation sur mobile
  margin: 10px auto;  // Centrer verticalement et horizontalement
`;

const LinkIcon = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorA};
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s;
  margin-left: 'auto'
  font-size: 32px;

  &:hover {
    color: ${color.colorA};
  }

  @media (max-width: 700px) {
    margin: 4px;
    font-size: 24px;
  }
`;

const Button = styled.div`
  text-decoration: none;
  border-style: none;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px; /* Ajoutez une marge horizontale pour espacer les boutons */
`;

const NewOne = styled(Button)`
  background-color: ${color.colorR};
`;

const OldOne = styled(Button)`
  background-color: ${color.colorR};
`;


const Body = styled.div`
  font-size: 16px;
  margin: 10px 5px;
  display: flex;
  justify-content: space-around; /* Ou space-between pour un espacement encore plus grand */
`;

class Register extends React.Component {
  state = {
    visible: false,
    reinscription: false,
    loading: false, step: 1,
    showAuthFail: false,
    phoneNumber: '',
    otp: '',
    confirmResult: null,
    isConnected: false,

    badOTP: false,
    retry: false,
    user: null
  };
  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  newText = "Nouveau";
  oldText = "Se réinscrire";

  handleNew = () => this.props.history.push('/public/add');
  handleOld = () => {
    const userData = localStorage.getItem('user');
    if (userData) {
      this.setState({ isConnected: true });
      this.props.history.push('/user/product-list');
    } else {
      this.setState({ isConnected: true });

    }

    this.setState({ visible: false, reinscription: true });

  };
  handleVisibility = () => this.setState(prevState => ({ visible: !prevState.visible }));
  handleReinscription = () => {


    this.setState(prevState => ({ reinscription: !prevState.reinscription }));
    this.setState(prevState => ({ visible: !prevState.visible }));
  }
  handlePhoneChange = (phoneNumber) => {
    this.setState({ phoneNumber });
  };


  componentDidMount() {

    base.fetch('/Familles', {
      context: this,
      asArray: true
    })
      .then(data => {
        this.setState({
          familles: data,
          sortBy: 'dateNewToOld',
          user: this.props.user
        }, () => {
          // After state is set, filter user
          this.filteredUser();
        });
      })
      .catch(error => console.log(error));
  }

  componentDidUpdate(prevProps, prevState) {
    // If user info or familles array has changed, update the filtered list
    if (prevProps.user !== this.props.user || prevState.familles !== this.state.familles) {
      this.filteredUser();

    }
  }

  normalizePhoneNumber = (number) => {
    const digits = number.replace(/\D/g, '');  // Remove non-digit characters
    // Check if the number already starts with '1' and only add '+1' if it doesn't
    return digits.startsWith('1') ? `+${digits}` : `+1${digits}`;
}


  
  filteredUser = () => {
    if (this.state.user) {
      const userPhoneNumber = this.normalizePhoneNumber(this.props.user.phoneNumber);
  
      // Filter to find famille with a phone number that matches the user's phone number
      const matchingFamille = this.state.familles.find(famille => {
        // Normalize each phone number and check if any matches the user's phone number
        return [
          famille.TelephoneMaison, 
          famille.UrgenceCellulaire, 
          famille.UrgenceContact, 
          famille.UrgencePhoneMaison, 
          famille.Cellulaire
        ].some(phone => phone && this.normalizePhoneNumber(phone) === userPhoneNumber);
      });
  
      // If a matching famille is found, use its name for the DropDown title
      if (matchingFamille) {
        this.setState({
          title: matchingFamille.PrenomPere + " " + matchingFamille.NomPere
        });
      }
    }
  };

  componentWillUnmount() {

  }

  handleModal = () => {
    this.setState({ modal: !this.state.modal });
  }

  handlePhoneChange = (phoneNumber) => {
    this.setState({ phoneNumber });
  };

  handleOTPChange = (e) => {
    this.setState({ otp: e.target.value });
  };

  sendOTP = () => {
    const { phoneNumber } = this.state;
    const { familles } = this.state;

    // Check if the phone number exists in the familias array
    const phoneExists = familles.some(familia => [
      this.normalizePhoneNumber(familia.TelephoneMaison), 
      this.normalizePhoneNumber(familia.UrgenceCellulaire), 
      this.normalizePhoneNumber(familia.UrgenceContact), 
      this.normalizePhoneNumber(familia.UrgencePhoneMaison), 
      this.normalizePhoneNumber(familia.Cellulaire)
    ].includes(phoneNumber));

    if (!phoneExists) {
      // Handle the case where the phone number is not found
      console.error('Phone number does not exist in the database');
      this.setState({ loading: false, showAuthFail: true, modal: false, reinscription: false, visible: false });
      return;
    }

    this.setState({ loading: true }); // Commencer l'animation de chargement
    const url = 'https://api.telnyx.com/v2/verifications/sms';
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer KEY018F414A1AD0A8CF9052E87EEDD52A09_nbB4pWmiOFqRppnTN5Ipzd'
    };
    const data = {
      phone_number: this.state.phoneNumber,
      verify_profile_id: '4900018f-407d-5320-c768-7c626fa5fc27'
    };

    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        setTimeout(() => {
          this.setState({ loading: false, step: 2 }); // Arrêter l'animation et passer à l'étape 2 après 5 secondes
        }, 7000); // 5 secondes d'attente
      })
      .catch(error => {
        console.error('Failed to send OTP:', error);
        this.setState({ loading: false, showAuthFail: true, modal: false });
      });
  };



  // In AuthBox.js
  verifyOTP = () => {
    const { otp, phoneNumber } = this.state;
    const url = `https://api.telnyx.com/v2/verifications/by_phone_number/${phoneNumber}/actions/verify`;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer KEY018F414A1AD0A8CF9052E87EEDD52A09_nbB4pWmiOFqRppnTN5Ipzd'
    };
    const data = {
      code: otp,
      verify_profile_id: '4900018f-407d-5320-c768-7c626fa5fc27'
    };

    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (data.data && data.data.response_code === 'accepted') {
          this.setState({ modal: false, showAuthFail: false });
          const userData = { phoneNumber, uid: "uid-from-response" }; // Assume you get a UID back
          localStorage.setItem('user', JSON.stringify(userData));
          this.props.updateUser(userData);
          this.props.history.push('/user/product-list');

        } else {
          this.setState({ badOTP: true, modal: false, modal: false, reinscription: false, visible: false });
        }
      })
      .catch(error => {
        console.error('Verification failed:', error);
        this.setState({ badOTP: true, modal: false, modal: false, reinscription: false, visible: false });
      });
  };

  nextStep = () => {
    this.setState({ step: 2 });
  };


  render() {
    const { children, style } = this.props;
    return (
      <React.Fragment>
        <div onClick={this.handleVisibility} style={style}>
          {children}
        </div>

        <Modal
          close
          visible={this.state.visible}
          title="Inscription"
          handleModal={this.handleVisibility}
        >
          <div style={{ fontFamily: "'Gatwick', sans-serif", textAlign: "center" }}>
            <p style={{ color: "#476072" }}>
              Pour les élèves déjà inscrits à l'école Les Compagnons, cliquez sur <strong>Se réinscrire</strong>.
            </p>
            <p style={{ color: "#476072" }}>
              Pour les nouveaux élèves à l'école Les Compagnons, cliquez sur <strong>Nouveau</strong>.
            </p>
          </div>
          <Body>
            <NewOne onClick={this.handleNew}>{this.newText}</NewOne>
            <OldOne onClick={this.handleOld}>{this.oldText}</OldOne>
          </Body>
        </Modal>

        {this.state.showAuthFail && (
          <Success
            visible={this.state.showAuthFail}
            title="Numéro Inconnu: Ce numéro n'existe pas dans notre système. Êtes-vous sûr(e) d'être inscrit(e) avec ce numéro?"
            okText="Annuler"
            cancelText='Réessayer'
            onConfirm={() => this.setState({ showAuthFail: false, phoneNumber: '' })}
            onCancel={() => this.setState({ showAuthFail: false, modal: true, phoneNumber: '', reinscription: true })}
          />
        )}
        {this.state.badOTP && (

          <Success
            visible={this.state.badOTP}
            title="Le code que vous avez saisi est incorrect. Veuillez réessayer."
            okText="Annuler"
            cancelText='Réessayer'
            onConfirm={() => this.setState({ badOTP: false, retry: false, otp: '', step: 1, phoneNumber: '' })}
            onCancel={() => this.setState({ showAuthFail: false, reinscription: true, retry: true, otp: '' })}
          />
        )}
        <Modal
          close
          visible={this.state.reinscription}
          title="Réinscription"
          handleModal={this.handleReinscription}
        >
          <div>
            {this.state.loading && <LottieAnimation options={this.defaultOptions} height={120} width={120} />}
            {this.state.step === 1 && !this.state.loading && !this.state.retry ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                <SmallPhoneInput value={this.state.phoneNumber} onChange={this.handlePhoneChange} text={"Entrer votre numéro de téléphone"} />

                <LinkIcon onClick={this.sendOTP}>
                  <FontAwesomeIcon icon={faCircleArrowRight} size="3x" style={{ color: color.olimpico }}/>
                </LinkIcon>
              </div>
            ) : null}
            {(this.state.step === 2 && !this.state.loading) || this.state.retry ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                <InputText
                  title="Code de vérification :"
                  placeholder='Reçu par SMS'
                  value={this.state.otp}
                  handleChange={this.handleOTPChange}
                />
                <LinkIcon onClick={this.verifyOTP}>
                  <FontAwesomeIcon icon={faCircleArrowRight} size="3x" style={{ color: color.olimpico }}/>
                </LinkIcon>
              </div>
            ) : null}
          </div>
        </Modal>





      </React.Fragment>
    );
  }
}

Register.propTypes = {
  updateUser: PropTypes.func.isRequired,
  // other props definitions
};


export default withRouter(Register);

