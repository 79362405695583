import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as color from '../../../color';
import Register from '../../../containers/user/Register';

const Logo = styled.img`
  position: absolute;  // Good for non-scrolling, but problematic if overlapping
  top: 0;
  right: 10px;
  height: auto;
  width: 50%;
  margin: 0 auto;
  display: block;

  @media (max-width: 768px) {
    position: relative;  // Keeps the logo within the flow of the document
    width: 80%;  // Adjusts the width to be more responsive
    height: auto;  // Maintains aspect ratio
    margin: 10px auto;  // Centers the logo and adds vertical spacing
    display: block;  // Ensures the logo takes its own line
  }
`;


const Image = styled.img`

  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  margin-bottom: 10px;



  @media (max-width: 768px) {
    height: 100%;  // Take full container height
    min-height: 150px;  // Minimum height to ensure visibility
  }
`;


const StyledLinkMap = styled.div`
  display: block;
  margin: 30px 0;  // Top and bottom margin set uniformly
  width: 650px;
  padding: 0;
  text-decoration: none;
  color: ${color.colorBlack};
  box-shadow: 0 1px 2px ${color.colorA};
  background-color: white;
  border-radius: 5px;
  overflow: hidden;  // Ensures that no child content can spill outside
  margin: 7px 10px;  // Specific left-right margin, may need adjustment
  height: auto;

  @media (max-width: 700px) {
    width: 95vw;
    height: 10%;
  }

  & > a {  // Targeting direct child anchor tags
    display: block;
    height: 10%;
    margin: 0;  // Removes any default margin
  }
`;


const ImageContainerGeneral = styled.div`
width: 100%;  // Default width
height: 100%;  // Default height
  overflow: hidden;

  @media (max-width: 768px) {  // Adjust for tablets
    width: 100%;  // Full width
    height: 100%;  // Adjusted height
  }

  @media (max-width: 480px) {  // Adjust for phones
    width: 100%;  // Full width
    height: 100%;  // Reduced height for smaller screens
  }
`;

const ImageContainerDetails = styled.div`
width: 100%;
min-height: 100%; // S'assure que la hauteur minimum est suffisante pour tout montrer
position: relative;
overflow: visible; // Permet à tout le contenu d'être visible

  @media (max-width: 768px) {
    width: 100%;
    height: auto; // Large height to accommodate detailed content
  }

  @media (max-width: 480px) {
    width: 100%;
    height: auto; // Substantial reduction for smaller screens
  }
`;


const Wrapper = styled.div`
padding: 5px;
display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${color.colorBg};
  border-radius: 5px;
  max-width: 650px;
  width: 100%;
  margin: auto;
  overflow: visible; // Assurez-vous que rien n'est caché

  @media (max-width: 768px) {
    padding: 5px;
    padding-top: 0;
    width: 95vw;
    height: auto;
  }
`;

const CoursContainer = styled.div`
  position: relative;
  width: 100%;
  padding-left: 20px;  // Assure un espace entre la barre et le texte
  overflow: hidden;  // Ajoutez cela si le contenu déborde
  padding-left: 10px;
`;

const StyledElement = styled.div`
position: absolute;
left: 0;
top: 0;
bottom: 0;
width: 15px;
background-color: ${color.colorA};
  
`;

const TextContainer = styled.div`
  margin-left: 20px;  // Réduisez la marge pour les petits écrans
  font-size: 14px;  // Assurez-vous que la taille du texte est lisible sur mobile

  @media (max-width: 768px) {
    margin-left: 10px;
    font-size: 12px;  // Vous pouvez ajuster la taille du texte encore plus petite si nécessaire
  }
`;


const ProductListItem = ({ id, item, index, updateUser }) => {
  const { title } = item;

  return title === 'details' ? (
    

    <Wrapper>
      <Register updateUser={updateUser}  style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "4px 8px",
        color: color.colorB,
        fontSize: "16px",
        transition: "color 0.2s",
      }}>
        <ImageContainerDetails>
          <divx style={{ fontFamily: "'Gatwick', sans-serif", color: color.colorA }}>
            <Logo src={`/img/logo.png`} alt="Event" />
            <CoursContainer>
              <StyledElement />
              <TextContainer>
                <h2 style={{ marginLeft: "30px" }}>Cours:</h2>
                <p style={{ marginLeft: "30px" }}>Mémorisation du coran</p>
                <p style={{ marginLeft: "30px" }}>Apprentissage de la langue arabe</p>
                <p style={{ marginLeft: "30px" }}>Education islamique</p>
                <p style={{ marginLeft: "30px" }}>Apprentissage de la langue française</p>
                <p style={{ marginLeft: "30px" }}>Préparation aux examens ministériels</p>
                <br />
                <br />
    
                <h2 style={{ marginLeft: "30px" }}>Samedi 9h15 à 13h15:</h2>
                <p style={{ marginLeft: "30px" }}> Les filles âgées de 4 à 12 ans</p>
                <p style={{ marginLeft: "30px" }}> Les garçons âgés de 4 à 12 ans</p>

                <br />
                <br />

                <h2 style={{ marginLeft: "30px" }}>Vendredi 18h00 à 21h00:</h2>
                <p style={{ marginLeft: "30px" }}>Les jeunes âgés de 13 à 18 ans </p>
                <p style={{ marginLeft: "30px" }}>
                  Classes séparées
                </p>
              </TextContainer>
              <Image src={`/img/subscribe.png`} alt="Subscribe" />
            </CoursContainer>
          </divx>
        </ImageContainerDetails>
      </Register>
    </Wrapper>
  ) : (
    <StyledLinkMap>
      <a href="https://www.google.com/maps/place/Centre+Communautaire+Islamique/@45.5657272,-73.5869168,19.25z/data=!4m14!1m7!3m6!1s0x4cc91eb094363677:0x7c7a6920d5c27937!2sCentre+Communautaire+Islamique!8m2!3d45.5657554!4d-73.5869!16s%2Fg%2F1wfvm845!3m5!1s0x4cc91eb094363677:0x7c7a6920d5c27937!8m2!3d45.5657554!4d-73.5869!16s%2Fg%2F1wfvm845?entry=ttu" target="_blank" rel="noopener noreferrer">
        <ImageContainerGeneral>
          <Image src={`/img/${title}.png`} alt="Event" />
        </ImageContainerGeneral>
      </a>
    </StyledLinkMap>
  );
};

ProductListItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default ProductListItem;
