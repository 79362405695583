import styled from 'styled-components';

import * as color from '../../color';

const Wrapper = styled.div`
  box-shadow: 0 1px 2px ${color.colorA};
  background-color: white;
  border-radius: 5px;
  justify-content: center;
  position: relative;
  padding: 10px 15px;
  padding-top: 0; // Assurez-vous qu'il n'y a pas de padding supplémentaire en haut
  max-width: 650px; // Largeur maximale pour des tailles modulables
  width: 100%; // Largeur responsive
  margin: auto; // Centrer le Wrapper
  height: auto; // La hauteur est déterminée par le contenu
  overflow: hidden; // Empêcher le contenu de déborder

  @media (max-width: 768px) {
    width: calc(100% - 30px); /* 15px de padding de chaque côté */
    margin-left: 15px; /* Ajouter une marge à gauche pour compenser le padding */
    margin-right: 15px; /* Ajouter une marge à droite pour compenser le padding */
    padding-top: 0; /* Aucun padding en haut pour mobile */
  }
`;

export default Wrapper;
