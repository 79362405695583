import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as color from '../../color';

const Content = styled.div`
  background-color: white;
  width: 90%;  // Use percentage width for better responsiveness
  max-width: 650px;  // Limit the maximum width
  position: fixed;  // Use fixed instead of absolute for better positioning on scroll
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  // Adjust for centering vertically and horizontally
  overflow: auto;
  z-index: 100;
  padding: 10px;
  border-radius: 5px;
  text-align: left;

  @media (max-width: 768px) {
    width: 95%;  // Increase width for smaller devices to use more space
    padding: 5px;  // Reduce padding on smaller screens
  }
`;

const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;  // Ensure the backdrop covers the entire screen
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
`;

const Close = styled.span`  // Use span for the close button for semantic correctness
  font-size: 24px;  // Adjust font size for visibility
  position: absolute;
  top: 10px;  // Position the close button in the top-right corner
  right: 10px;
  cursor: pointer;

  &:hover {
    color: ${color.colorO};  // Use a theme color for hover state
  }

  @media (max-width: 768px) {
    font-size: 18px;  // Slightly smaller font size on mobile
  }
`;

const Title = styled.div`
  font-size: 18px;
  text-align: center;
  color: ${color.colorA};
  border-bottom: 1px solid ${color.colorA};
  padding-bottom: 5px;  // Add padding to separate title from content

  @media (max-width: 768px) {
    font-size: 16px;  // Adjust font size for mobile devices
  }
`;


const Body = styled.div`
  font-size: 16px;
  margin: 25px;
  @media (max-width: 700px) {
    font-size: 14px;
    margin: 0;
  }
`;

const Modal = props => {
  return props.visible ? (
    <React.Fragment>
      <Backdrop onClick={props.handleModal} />
      <Content>
        {props.close && (
          <Close onClick={props.handleModal} className="ion-md-close" />
        )}
        <Title>{props.title}</Title>
        <Body>{props.children}</Body>
      </Content>
    </React.Fragment>
  ) : null;
};

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  close: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired
  ])
};
export default Modal;
