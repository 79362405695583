export const colorG = '#009432';
export const colorB = '#227093';
export const colorR = '#84764d';
export const colorO = '#F79F1F';
export const colorA = '#006266';
export const colorBlack = '#1b1f23';
export const colorRed = '#c70000';
export const colorBg = '#FFFCED';
export const colorW = '#25D366';
export const colorD = '#85BB65';
export const colorY = '#FFD700';
export const colorC = '#F07700';
export const colorBl = '#C0C0C0';
export const olimpico = '#84764d';
export const greenOlimpico = '#17302a'; 


