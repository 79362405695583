import React from 'react';
import base from '../../firebase';
import PropTypes from 'prop-types';
import FilterLocation from '../../components/public/Filter/Location/FilterLocation';
import FilterCategory from '../../components/public/Filter/Category/FilterCategory';
import SortBy from '../../components/public/Filter/SortBy';
import FilterBox from '../../components/public/Filter/FilterBox';
import styled from 'styled-components';
import * as color from '../../color';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import InputTextarea from '../../components/user/Form/InputTextarea';
import Info from '../../components/utility/Info';
import { withRouter } from 'react-router';
import DevoirList from '../../containers/user/DevoirList';
import Lottie from 'react-lottie';
import animationData from '../../containers/public/Loading.json';
import Create from '@material-ui/icons/AddCircleOutline';

const ButtonLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 5px;
  background-color: ${color.colorRed};
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  box-shadow: 2px 2px 3px ${color.colorA};

  &:hover {
    background-color: ${color.colorD};
  }
`;

const Wrapper2 = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: White;
  border-radius: 5px;
  max-width: 650px;
  width: 100%;
  margin: 20px auto;
  overflow: visible;
  @media (max-width: 768px) {
    padding: 15px;
    width: 95vw;
    margin: 15px auto;
  }
`;

const LinkIcon = styled.button`
  display: inline-flex;
  align-items: right;
  justify-content: right;
  margin: 4px 8px;
  color: ${color.colorA};
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s;
  font-size: 32px;
  &:hover {
    color: ${color.colorA};
  }
  @media (max-width: 700px) {
    margin: 4px;
    font-size: 24px;
  }
`;

const LottieAnimation = styled(Lottie)`
  width: 60%;
  margin: 10px auto;
`;

class Devoir extends React.Component {
  state = {
    modalLocation: false,
    modalCategory: false,
    locations: [],
    category: [],
    subcategory: [],
    familles: [],
    isStudent: false,
    isAdmin: false,
    isRestricted: false,
    categoryTitle: '',
    description: '',
    classId: '',
    error: false,
    success: false,
    devoirs: [],
    loading: true, // Adding loading state
    message: ''
  };

  normalizePhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';
    const cleaned = phoneNumber.replace(/\D/g, '');
    return cleaned.length === 10 ? `+1${cleaned}` : `+${cleaned}`;
  }

  componentDidMount() {
    this.fetchDevoirs();
    this.state.isAdmin = [
      "+15146532079", "+15145770596"
    ].includes(this.props.user.phoneNumber);
    this.state.isRestricted = [
      "+15144629373", "+14384101920", "+15146913866", "+15142613284",
      "+15145527060", "+14382828612", "+14382201778"
    ].includes(this.props.user.phoneNumber);

    if (this.state.isAdmin) this.setState({ isAdmin: true });
    if (this.state.isRestricted) this.setState({ isRestricted: true });
    if (!this.state.isAdmin && !this.state.isRestricted) this.setState({ isStudent: true });
  }

  handleModalLocation = () => {
    this.setState({ modalLocation: !this.state.modalLocation });
    if (this.state.locations.length === 0) {
      this.fetchLocation();
    }
  };

  handleModalCategory = () => {
    this.setState({ modalCategory: !this.state.modalCategory });

    if (this.state.category.length === 0) {
      this.fetchCategory();
    }
  };

  fetchLocation = () => {
    base
      .fetch('/products', {
        context: this,
        asArray: true
      })
      .then(data => this.setState({ locations: data }))
      .catch(error => console.log(error));
  };

  fetchCategory = () => {
    base
      .fetch('/category', {
        context: this
      })
      .then(data => this.setState({ category: data }))
      .catch(error => console.log(error));
  };

  handleSubcategory = categoryTitle => {
    if (categoryTitle === 'Toutes les classes') {
      this.props.handleResetFilters();
    } else {
      const subcategories = this.state.category[categoryTitle] || [];
      this.setState({ subcategory: subcategories, categoryTitle: categoryTitle });
    }
  };

  handleDescriptionChange = event => {
    const value = event.target.value;
    if (value.length <= 1500) {
      this.setState(prevState => ({ ...prevState, description: value }));
    }
  };

  getClassPid = (className) => {
    const classes = {
      "1": { "nom": "1re année" },
      "2": { "nom": "2e année" },
      "3": { "nom": "3e année" },
      "4": { "nom": "4e année" },
      "5": { "nom": "5e année" },
      "6": { "nom": "6e année" },
      "7": { "nom": "7e année" },
      "8": { "nom": "8e année" },
      "9": { "nom": "9re année" },
      "10": { "nom": "Avancés" },
      "11": { "nom": "Débutants" },
      "12": { "nom": "Intermédiaires" },
      "13": { "nom": "maternelle" },
      "14": { "nom": "pré-maternelle" }
    };

    for (const [id, classData] of Object.entries(classes)) {
      if (classData.nom === className.split('_')[0]) {
        return id;
      }
    }
    return null;
  };

  date = Date.now();
  class = this.props.currentCategory;
  classId = this.props.currentCategory + this.date;

  validate = () => {
    if (!this.class) {
      this.setState({ error: true, message: "Il faut choisir ta classe, cliques sur classe" });
      return false;
    }
    if (!this.state.description) {
      this.setState({ error: true, message: "Il faut saisir le devoir, SoubhanaLah! 😅" });
      return false;
    }
    this.setState({ error: false });
    return true;
  }

  handleConfirm = () => {
    if (this.validate()) {
      base.update(`/Devoirs/${this.classId}`, {
        data: {
          description: this.state.description,
          date: this.date
        }
      })
        .then(() => {
          this.setState(prevState => ({ ...prevState, visible: false }));
          this.setState({ success: true });
        })
        .catch(error => console.log(error));
    }
  };

  fetchDevoirs = () => {
    base
      .fetch('/Devoirs', {
        context: this,
        asArray: true
      })
      .then(data => {
        this.setState({ devoirs: data, loading: false }); // Set loading to false once the data is fetched
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false }); // Ensure loading is also set to false on error
      });
  };

  render() {
    const { loading, devoirs } = this.state;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <React.Fragment>
        <Info
          visible={this.state.error}
          title={this.state.message}
          okText="Ok"
          onConfirm={() => this.setState({ error: false })}
        />
        <Info
          visible={this.state.success}
          title="Le devoir été envoyé avec succès! 👆🏽"
          okText="Ok"
          onConfirm={() => {
            this.setState({ success: false })
            this.props.history.push('/user/devoir')
          }}
        />
        <FilterBox>
          <FilterCategory
            visible={this.state.modalCategory}
            category={this.state.category}
            handleModal={this.handleModalCategory}
            currentCategory={this.props.currentCategory}
            handleCategory={this.props.handleCategory}
            handleSubcategory={this.handleSubcategory}
            subcategory={this.state.subcategory}
            categoryTitle={this.state.categoryTitle}
          />
        </FilterBox>

        <Wrapper2>
          <React.Fragment>
            <InputTextarea
              title="Nouveau devoir:"
              placeholder=""
              value={this.state.description}
              handleChange={this.handleDescriptionChange}
            />
            <LinkIcon onClick={this.handleConfirm}>
              <Create   style={{ color: color.olimpico }}/>
            </LinkIcon>

            {loading ? (
              <LottieAnimation options={defaultOptions} height={120} width={120} />
            ) : (
              <DevoirList devoirs={devoirs} user={this.props.user}/>
            )}
          </React.Fragment>
        </Wrapper2>
      </React.Fragment>
    );
  }
}

Devoir.propTypes = {
  user: PropTypes.object.isRequired
};

export default withRouter(Devoir);
