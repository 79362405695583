import React, { Component } from 'react';
import base from '../firebase';
import { Route, Switch } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from '../containers/public/Loading.json';
import styled from 'styled-components';
import AddProduct from '../containers/public/AddProduct';
import EditProduct from '../containers/user/EditProduct';
import ErrorMessage from '../components/ErrorMessage';
import Filter from '../containers/public/Filter';
import Products from '../containers/user/Products';
import Renew from '../containers/user/Renew';
import AbsenceChartComponent from '../components/user/Product/AbsenceChartItem';
import Devoir from '../containers/public/Devoir';
import General from '../containers/public/General';
import WhatsApp from '@material-ui/icons/Mail';
import Report from '@material-ui/icons/List';

import * as color from '../color';
import Laclasse from '../containers/user/LaClasse';
import MessageModal from '../containers/user/Message';
import ExportModal from '../containers/user/Export';

const ButtonLink = styled.a`
  width: 650px;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.greenOlimpico};
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
  box-shadow: 2px 2px 3px ${color.colorBlack};
  padding: 3px 5px;
  margin-left: 10px;
  margin-right: 10px;
  &:hover {
    background-color: ${color.greenOlimpico};
  }
  @media (max-width: 700px) {
    width: 95vw;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const FlexColumn = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 700px) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

class User extends Component {
  state = {
    products: [],
    familles: [],
    filteredProductList: [],
    search: '',
    location: '',
    category: '',
    sortBy: '2024/2025',
    DateInscription: '',
    femalesList: [],
    malesList: [],
    loading: true,
    devoirVisible: false,
    count: 0,
    classes: [],
    isModalOpen: false,
    message: ''
  };

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  handleDevoirClose = () => {
    this.setState({ devoirVisible: false });
  };

  updateCount = () => {
    if (!this.isUnmounted) {
      this.setState({ count: this.state.filteredProductList.length });
    }
  };

  loadData = () => {
    base
      .fetch('/Eleves', {
        context: this,
        asArray: true
      })
      .then(data => {
        this.setState({
          products: data,
          filteredProductList: data,
          classes: data,
          loading: false
        }, () => {
          this.sortBy();
          this.updateSexLists();
          this.updateCount();
        });
      })
      .catch(error => console.log(error));

    base
      .fetch('/Familles', {
        context: this,
        asArray: true
      })
      .then(data =>
        this.setState({
          familles: data,
          loading: false
        })
      )
      .catch(error => console.log(error));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.category !== this.state.category) {
      this.filteredProduct();
      this.updateCount();
    }
    if (prevState.sortBy !== this.state.sortBy) {
      this.filteredProduct();
      this.sortBy();
      this.updateCount();
      this.updateSexLists();
    }
    if (prevState.filteredProductList !== this.state.filteredProductList) {
      this.updateSexLists();
      this.updateCount();
    }
  }

  handleSearch = event => {
    this.setState({ search: event.target.value });
  };

  handleClearSearch = () => {
    this.setState({ search: '' });
  };

  handleCategory = text => {
    this.setState({ category: text });
  };

  handleResetFilters = () => {
    this.setState({
      category: ''
    }, this.filteredProduct);
  };

  handleResetSortBy = () => {
    this.setState({
      sortBy: ''
    }, this.filteredProduct);
  };

  filteredProduct = () => {
    const { products, category, classes } = this.state;
    const [niveau, sexe] = category.split('_');

    let filteredProducts = classes.filter(product => {
      const matchNiveau = niveau ? product.Niveau === niveau : true;
      const matchSexe = sexe ? product.sexe === sexe : true;
      return matchNiveau && matchSexe;
    });

    this.setState({ classes: filteredProducts });
  };

  updateSexLists = () => {
    const { filteredProductList } = this.state;
    const malesList = filteredProductList.filter(product => product.sexe === 'M');
    const femalesList = filteredProductList.filter(product => product.sexe === 'F');
    this.setState({ malesList, femalesList });
  };

  sortBy = () => {
    const { products, sortBy } = this.state;
    let filteredProducts;

    if (sortBy === '2024') {
      filteredProducts = products.filter(product => {
        const dateInscriptionMatch = true;
        return dateInscriptionMatch;
      });
      this.setState({ filteredProductList: filteredProducts });
    }
    else if (sortBy === '2024/2025') {
      filteredProducts = products.filter(product => {
        const dateInscriptionMatch = product.DateInscription && /^2024-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(product.DateInscription);
        return dateInscriptionMatch;
      });
      this.setState({ filteredProductList: filteredProducts });
    }
    else if (sortBy === 'Approved') {
      filteredProducts = products.filter(product => product.Approved === 'Yes');
      this.setState({ filteredProductList: filteredProducts });
    }
  };

  handleSortBy = event => {
    this.setState({ sortBy: event.target.value });
  };

  getPhoneNumbers = () => {
    const canadianFormat = /^\+1\d{10}$/;
    const { filteredProductList, familles } = this.state;
    const phoneNumbers = filteredProductList
      .map(product => {
        const famille = familles.find(fam => fam.key && product.IdFamille && fam.key.toLowerCase() === product.IdFamille.toLowerCase());
        if (famille) {
          const normalizedNumber = this.normalizePhoneNumber(famille.TelephoneMaison);
          return canadianFormat.test(normalizedNumber) ? normalizedNumber : null;
        }
        return null;
      })
      .filter(number => number); // Filter out any null values
    return phoneNumbers;
  };

  normalizePhoneNumber = (number) => {
    const digits = number.replace(/\D/g, '');  // Remove non-digit characters
    return digits.startsWith('1') ? `+${digits}` : `+1${digits}`;
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { loading, products, filteredProductList, category, sortBy, malesList, femalesList, classes, count, isModalOpen, message } = this.state;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const phoneNumbers = this.getPhoneNumbers();
    //const phoneNumbersString = phoneNumbers.join(',');

    return (
      <React.Fragment>
        {loading ? (
          <Lottie options={defaultOptions} height={120} width={120} />
        ) : this.props.user ? (
          <Switch>
            <Route
              exact
              path="/public/add"
              component={props => (
                <AddProduct {...props} user={this.props.user} />
              )}
            />
            <Route
              exact
              path="/user/edit/:eleveKey/:familleKey"
              component={props => (
                <EditProduct
                  {...props}
                  user={this.props.user}
                  productList={products}
                  familleList={this.state.familles}
                />
              )}
            />
            <Route
              exact
              path="/user/renew/:eleveKey/:familleKey"
              component={props => (
                <Renew
                  {...props}
                  user={this.props.user}
                  productList={products}
                  familleList={this.state.familles}
                />
              )}
            />
            <Route
              exact
              path="/user/product-list"
              component={props => (
                <React.Fragment>
                  <General
                    user={this.props.user}
                    handleLocation={this.handleLocation}
                    currentLocation={this.state.location}
                    handleCategory={this.handleCategory}
                    currentCategory={category}
                    handleSortBy={this.handleSortBy}
                    currentSortBy={sortBy}
                    handleResetFilters={this.handleResetFilters}
                    count={count}
                  />
                  {sortBy === "Approved" && (
                    <FlexRow>
                      <ButtonLink onClick={this.openModal}>
                        <WhatsApp style={{ color: color.olimpico, fontSize: 35 }} />
                        <span style={{ marginRight: 12 }}>
                          Diffuser un message sms
                        </span>
                      </ButtonLink>
                      <MessageModal
                        isOpen={isModalOpen}
                        onRequestClose={this.closeModal}
                        phoneNumbers={this.getPhoneNumbers()}
                      />
                    </FlexRow>
                  )}
                  {products.length > 0 && (
                    <Products
                      user={this.props.user}
                      filteredProduct={filteredProductList}
                      handleUserLogin={this.props.handleUserLogin}
                      currentSortBy={sortBy}
                    />
                  )}
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/user/classes-list"
              component={props => (
                <React.Fragment>
                  <FlexRow>
                    <ButtonLink onClick={this.openModal}>
                      <Report style={{ color: color.olimpico, fontSize: 35 }} />
                      <span style={{ marginRight: 12 }}>
                        Exporter en format Excel
                      </span>
                    </ButtonLink>
                    <ExportModal
                      isOpen={isModalOpen}
                      onRequestClose={this.closeModal}
                      products={products}
                    />
                  </FlexRow>

                  {products.length > 0 && (
                    <Laclasse
                      user={this.props.user}
                      filteredProduct={classes}
                      handleUserLogin={this.props.handleUserLogin}
                    />
                  )}
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/user/chart"
              component={props => (
                <React.Fragment>
                  <Filter
                    user={this.props.user}
                    handleLocation={this.handleLocation}
                    currentLocation={this.state.location}
                    handleCategory={this.handleCategory}
                    currentCategory={category}
                    handleSortBy={this.handleSortBy}
                    currentSortBy={sortBy}
                    handleResetFilters={this.handleResetFilters}
                    count={count}
                  />
                  {products.length > 0 && (
                    <FlexRow>
                      <FlexColumn>
                        <AbsenceChartComponent malesList={malesList.length} femalesList={femalesList.length} />
                      </FlexColumn>
                      En cours, page pas encore finie! 🚧
                    </FlexRow>
                  )}
                </React.Fragment>
              )}
            />
            <Route
              exact
              path="/user/devoir"
              component={props => (
                <React.Fragment>
                  <Devoir
                    user={this.props.user}
                    handleLocation={this.handleLocation}
                    currentLocation={this.state.location}
                    handleCategory={this.handleCategory}
                    currentCategory={category}
                    handleSortBy={this.handleSortBy}
                    currentSortBy={sortBy}
                    handleResetFilters={this.handleResetFilters}
                  />
                </React.Fragment>
              )}
            />
          </Switch>
        ) : (
          <ErrorMessage>You are not signed in.</ErrorMessage>
        )}
      </React.Fragment>
    );
  }
}

export default User;
