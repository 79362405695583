import React from 'react';
import PropTypes from 'prop-types';
import LoadMoreProduct from '../../components/public/LoadMoreProduct';
import ErrorMessage from '../../components/ErrorMessage';
import base from '../../firebase';
import Lottie from 'react-lottie';
import animationData from '../../containers/public/Loading.json';
import styled from 'styled-components';
import RecipeReviewCard from '../../components/user/Product/CardListItem';

const LottieAnimation = styled(Lottie)`
  width: 60%;  // Taille appropriée pour l'animation sur mobile
  margin: 10px auto;  // Centrer verticalement et horizontalement
`;

class Products extends React.Component {
  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
  state = {
    limit: 800,
    familles: [],
    loading: true,
    badgeNumber: 0
  };

  handleBadge = (badge) => {
    this.setState(prevState => ({
      badgeNumber: prevState.badgeNumber + badge
    }), () => {
      console.log("updated badge", this.state.badgeNumber);
    });
  };

  componentDidMount() {
    this._isMounted = true;
    base
      .fetch('/Familles/', {
        context: this,
        asArray: true
      })
      .then(data => {
        if (this._isMounted) {
          this.setState({
            familles: data,
            sortBy: 'dateNewToOld',
            loading: false, // Set loading to false once the data is fetched
          });
        }
      })
      .catch(error => {
        console.log(error);
        if (this._isMounted) {
          this.setState({ loading: false }); // Ensure loading is also set to false on error
        }
      });

    this.fetchDevoirs();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchDevoirs = () => {
    base.fetch('/Devoirs', { context: this, asArray: true })
      .then(data => {
        this.handleBadge(data.length);
      })
      .catch(error => {
        console.log(error);
      });
  };

  productList = () => {
    const { limit, familles, badgeNumber } = this.state;
    const { filteredProduct } = this.props;

    return filteredProduct.slice(0, limit).map((item, index) => {
      if (!item.IdFamille) {
        return null;
      }
      
      const famille = familles.find(fam => fam.key.toLowerCase() === item.IdFamille.toLowerCase());
      if (!famille) return null;

      return (
        <RecipeReviewCard
          key={item.key}
          id={item.key}
          item={item}
          index={index}
          user={this.props.user}
          famille={famille}
          badgeNumber={badgeNumber}
        />
      );
    });
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return <LottieAnimation options={this.defaultOptions} height={120} width={120} />;
    }
    return (
      <div>
        {this.productList().length > 0 ? (
          this.productList()
        ) : (
          <ErrorMessage>Rien trouvé</ErrorMessage>
        )}
        {this.props.filteredProduct.length > this.state.limit && (
          <LoadMoreProduct loadMore={this.loadMore} />
        )}
      </div>
    );
  }
}

Products.propTypes = {
  filteredProduct: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

export default Products;
