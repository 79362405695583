import React from 'react';
import base from '../../firebase';
import PropTypes from 'prop-types';
import FilterLocation from '../../components/public/Filter/Location/FilterLocation';
import FilterCategory from '../../components/public/Filter/Category/FilterCategory';
import SortBy from '../../components/public/Filter/SortBy';
import FilterBox from '../../components/public/Filter/FilterBox';
import styled from 'styled-components';
import * as color from '../../color';
import { Link } from 'react-router-dom';

const ButtonLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px 5px; // Augmentez le padding vertical si nécessaire
  background-color: ${color.colorBlack}; // Couleur rouge définie dans votre fichier de couleurs
  color: white; // Texte blanc pour contraster avec le fond rouge
  text-decoration: none; // Supprime le soulignement des liens
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px; // Taille de police appropriée pour le bouton
  transition: background-color 0.2s;
  box-shadow: 2px 2px 3px ${color.colorBlack};

  &:hover {
    background-color: ${color.colorD}; // Assombrir le rouge au survol
  }
`;

class General extends React.Component {
  state = {
    modalLocation: false,
    modalCategory: false,
    locations: [],
    category: [],
    subcategory: [],
    familles: [],
    isStudent: false,
    isAdmin: false,
    isRestricted: false,
    categoryTitle:''
  };

  normalizePhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';
    // Retire les espaces et les caractères non-numériques, puis vérifie la longueur et ajoute '+1' si nécessaire
    const cleaned = phoneNumber.replace(/\D/g, '');
    return cleaned.length === 10 ? `+1${cleaned}` : `+${cleaned}`;
  }

  componentDidMount() {
    this.state.isAdmin = [
      "+15146532079", "+15145770596"
    ].includes(this.props.user.phoneNumber);
    this.state.isRestricted = [
      "+15144629373", "+14384101920", "+15146913866", "+15142613284",
      "+15145527060", "+14382828612", "+14382201778"
    ].includes(this.props.user.phoneNumber);

    if (this.state.isAdmin) this.setState({ isAdmin: true });
    if (this.state.isRestricted) this.setState({ isRestricted: true });
    if (!this.state.isAdmin && !this.state.isRestricted) this.setState({ isStudent: true });
  }


  // control modal visibility for location
  handleModalLocation = () => {
    this.setState({ modalLocation: !this.state.modalLocation });
    if (this.state.locations.length === 0) {
      this.fetchLocation();
    }
  };

  // control modal visibility for category
  handleModalCategory = () => {
    this.setState({ modalCategory: !this.state.modalCategory });

    if (this.state.category.length === 0) {
      this.fetchCategory();
    }
  };

  // Fetch location list from firebase
  fetchLocation = () => {
    base
      .fetch('/products', {
        context: this,
        asArray: true
      })
      .then(data => this.setState({ locations: data }))
      .catch(error => console.log(error));
  };

  // Fetch location list from firebase
  fetchCategory = () => {
    base
      .fetch('/category', {
        context: this
      })
      .then(data => this.setState({ category: data }))
      .catch(error => console.log(error));
  };

  // Set subcategory list on click
  handleSubcategory = categoryTitle => {
    if (categoryTitle === 'Toutes les classes') {
      // Call a method to reset filters
      this.props.handleResetFilters();
    } else {
      const subcategories = this.state.category[categoryTitle] || [];
      this.setState({ subcategory: subcategories, categoryTitle: categoryTitle });
    }
  };
  



  render() {
    return (
      <FilterBox>

 
        {(this.state.isAdmin || this.state.isRestricted) && (
          <SortBy
          handleSortBy={this.props.handleSortBy}
          currentSortBy={this.props.currentSortBy}
          count={this.props.count}
        />
        )}

        
      </FilterBox>
    );
  }
}

General.propTypes = {
  user: PropTypes.object.isRequired
};

export default General;
