import React, { Fragment } from 'react';
import styled from 'styled-components';
import base from '../../firebase'; // Assuming 'base' is set up correctly for Firebase operations
import InputText from '../../components/user/Form/InputText';
import InputDropDown from '../../components/user/Form/InputDropDown';
import SubmitButton from '../../components/user/Form/SubmitButton';
import Message from '../../components/user/Form/Message';
import DateInput from './DateInput';
import DateInputAssurance from './DateInputAssuranceMaladie';
import 'react-phone-number-input/style.css';
import CustomPhoneInput from './StyledPhoneInput';
import Confirm from '../../components/utility/Confirm';
import Lottie from 'react-lottie';
import animationData from '../../containers/public/Loading.json';
import * as color from '../../color';

const Wrapper2 = styled.div`
  padding: 20px; // Uniform padding around all sides
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: White;
  border-radius: 5px;
  max-width: 650px;
  width: 100%;
  margin: 20px auto; // Adds margin on the top and bottom as well
  overflow: visible;

  @media (max-width: 768px) {
    padding: 15px; // Slightly less padding on mobile for more space efficiency
    width: 95vw; // Adjusts width to be responsive
    margin: 15px auto; // Less margin on mobile
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: calc(100% - 20px); // Adjust width to account for padding

  @media (max-width: 700px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const FlexColumn = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 700px) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const LottieAnimation = styled(Lottie)`
  width: 60%;  // Taille appropriée pour l'animation sur mobile
  margin: 10px auto;  // Centrer verticalement et horizontalement
`;

class AddProduct extends React.Component {
  state = {
    step: 1,
    // Eleves Info
    prenom: '',
    nom: '',
    DDN_AAAA: '',
    DDN_MM: '',
    DDN_JJ: '',
    sexe: 'Masculin',
    DejaSuiviCoursDArabe: 'Oui',
    LangueMaison: 'Arabe',
    NiveauScolairePublic: 'Prématernelle',
    NiveauScolaireIslamique: 'Débutant',
    NomAncienMadrassa: '',
    LieuNaissance: '',
    NoAssuranceMaladie: '',
    ExpirationAssurance_AAAA: '',
    ExpirationAssurance_MM: '',
    ProblemesSante: 'Non',
    // Familles Info
    NomPere: '',
    PrenomPere: '',
    NomMere: '',
    PrenomMere: '',
    TelephoneMaison: '',
    Cellulaire: '',
    UrgenceContact: '',
    UrgenceCellulaire: '',
    UrgencePhoneMaison: '',
    Ville: '',
    Rue: '',
    NoCivique: '',
    Appartement: '',
    CodePostal: '',
    // IDs and Status
    IDUser: '',
    showSuccess: false,
    error: '',
    FactureTotale: '',
    MontantPaye: '500',
    MontantRestant: '',
    showModal: false,
    loading: true,
    familyRecordExists: false,
    familyId: '',
    famillesForPhones: []
  };
  
  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  handleAmountChange = (event) => {
    const amount = event.target.value;
    this.setState({
      FactureTotale: '500',
      MontantPaye: amount,
      MontantRestant: (500 - amount).toString()
    });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  nextStep = () => {
    this.setState(prevState => ({ step: prevState.step + 1 }));
  };

  prevStep = () => {
    this.setState(prevState => ({ step: prevState.step - 1 }));
  };

  handleChange = (event, name) => {
    let { value } = event.target;

    if (name === 'UrgenceCellulaire' || name === 'Cellulaire' || name === 'TelephoneMaison') {
      value = value.replace(/[^0-9]/g, '').slice(0, 10);
    }
    if (name === 'NoCivique' || name === 'Appartement') {
      value = value.replace(/[^0-9]/g, '').slice(0, 6);
    }
    if (name === 'CodePostal') {
      value = value.replace(/\s+/g, '').toUpperCase(); // Enlever tous les espaces et convertir en majuscules
      if (value.length > 3) {
        value = value.slice(0, 3) + ' ' + value.slice(3, 6); // Ajouter un espace entre le 3ème et 4ème caractère
      }
      value = value.slice(0, 7); // Limiter à 7 caractères incluant l'espace
    }
    this.setState({ [name]: value });
  };

  validateFields = () => {
    const { step, prenom, nom, DDN_AAAA, DDN_MM, DDN_JJ, sexe, DejaSuiviCoursDArabe, LangueMaison, NiveauScolairePublic,
      NiveauScolaireIslamique, NomAncienMadrassa, LieuNaissance, NoAssuranceMaladie, ExpirationAssurance_AAAA,
      ExpirationAssurance_MM, ProblemesSante, NomPere, PrenomPere, NomMere, PrenomMere, TelephoneMaison, Cellulaire,
      UrgenceContact, UrgenceCellulaire, Ville, Rue, NoCivique, Appartement, CodePostal } = this.state;

    let missingFields = false;
    let errorMessage = '';

    switch (step) {
      case 1:
        if (!prenom.trim() || !nom.trim() || !DDN_AAAA || !DDN_MM || !DDN_JJ || !sexe.trim() || !LieuNaissance.trim()) {
          missingFields = true;
          errorMessage = 'Veuillez remplir tous les champs.';
        }
        break;
      case 3:
        if (!DejaSuiviCoursDArabe.trim() || !LangueMaison.trim() || !NiveauScolairePublic.trim() || !NiveauScolaireIslamique.trim()) {
          missingFields = true;
          errorMessage = 'Veuillez remplir tous les champs.';
        }
        break;
      case 4:
        if (!NomPere.trim() || !PrenomPere.trim() || !NomMere.trim() || !PrenomMere.trim() || !UrgenceContact.trim()) {
          missingFields = true;
          errorMessage = 'Veuillez remplir tous les champs.';
        }
        break;
      case 5:
        if (!PrenomMere.trim() || !TelephoneMaison.trim() || !Cellulaire.trim() || !UrgenceCellulaire.trim()) {
          missingFields = true;
          errorMessage = 'Veuillez remplir tous les champs.';
        }
        break;
      case 6:
        if (!Ville.trim() || !Rue.trim() || !NoCivique.trim() || !CodePostal.trim()) {
          missingFields = true;
          errorMessage = 'Veuillez remplir tous les champs, excepté Appartement si non applicable.';
        }
        break;
      default:
        break;
    }

    if (missingFields) {
      this.setState({ error: errorMessage });
      return false;
    }

    this.setState({ error: '' }); // Clear any previous errors
    return true;
  };

  nextStep = () => {
    if (this.validateFields()) {
      this.setState(prevState => ({ step: prevState.step + 1 }));
    }
  };

  handleDateChange = ({ year, month, day }) => {
    this.setState({
      DDN_AAAA: year,
      DDN_MM: month + 1, // month is zero-indexed, adjust if needed for your usage
      DDN_JJ: day
    });
  };

  handlePhoneChange = (Cellulaire) => {
    this.setState({ Cellulaire });
  };

  handleTelephoneMaisonChange = (TelephoneMaison) => {
    this.setState({ TelephoneMaison });
  };

  handleUrgenceCellulaireChange = (UrgenceCellulaire) => {
    this.setState({ UrgenceCellulaire });
  };

  handleDejaSuiviCoursDArabeChange = (DejaSuiviCoursDArabe) => {
    this.setState({ DejaSuiviCoursDArabe });
  };

  normalizePhoneNumber = (number) => {
    const digits = number.replace(/\D/g, '');  // Remove non-digit characters
    return digits.startsWith('1') ? `+${digits}` : `+1${digits}`;
  }

  checkFamilyRecordExists = async () => {
    const { TelephoneMaison, Cellulaire, UrgenceCellulaire } = this.state;
  
    try {
      // Fetch the Familles data
      const data = await base.fetch('/Familles', {
        context: this,
        asArray: true,
      });
  
      // Normalize the user's input phone numbers
      const userPhoneNumbers = [
        this.normalizePhoneNumber(TelephoneMaison),
        this.normalizePhoneNumber(Cellulaire),
        this.normalizePhoneNumber(UrgenceCellulaire),
      ];
  
      console.log("User's normalized phone numbers:", userPhoneNumbers);
  
      let familyRecordExists = false;
      let familyId = '';
  
      // Check each family's phone numbers against the user's input
      data.forEach(familia => {
        const familyPhoneNumbers = [
          this.normalizePhoneNumber(familia.TelephoneMaison),
          this.normalizePhoneNumber(familia.UrgenceCellulaire),
          this.normalizePhoneNumber(familia.UrgenceContact),
          this.normalizePhoneNumber(familia.UrgencePhoneMaison),
          this.normalizePhoneNumber(familia.Cellulaire),
        ];
  
        console.log(`Checking family ID ${familia.key}:`, familyPhoneNumbers);
  
        // If any of the family's phone numbers match the user's input, set the family record as existing
        if (userPhoneNumbers.some(number => familyPhoneNumbers.includes(number))) {
          familyRecordExists = true;
          familyId = familia.key;
        }
      });
  
      console.log("Family record exists:", familyRecordExists, "Family ID:", familyId);
  
      if (familyRecordExists) {
        this.setState({ familyRecordExists, familyId });
        this.pushEleveData();
      } else {
        this.setState({ familyRecordExists: false, familyId: '' });
        this.pushEleveData(); // Proceed with submission if no record is found
      }
    } catch (error) {
      console.error("Error checking for existing family record:", error);
      this.setState({ error: "Failed to check existing records." });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.validateFields()) {
      return;
    }

    // Before attempting to add a new family record, check if the record already exists
    this.checkFamilyRecordExists();
  };
  today = new Date();
  pushEleveData = async () => {
    const eleveData = {
      prenom: this.state.prenom,
      nom: this.state.nom,
      DDN_AAAA: this.state.DDN_AAAA,
      DDN_MM: this.state.DDN_MM,
      DDN_JJ: this.state.DDN_JJ,
      sexe: this.state.sexe,
      DejaSuiviCoursDArabe: this.state.DejaSuiviCoursDArabe,
      LangueMaison: this.state.LangueMaison,
      NiveauScolairePublic: this.state.NiveauScolairePublic,
      NiveauScolaireIslamique: this.state.NiveauScolaireIslamique,
      NomAncienMadrassa: this.state.NomAncienMadrassa,
      LieuNaissance: this.state.LieuNaissance,
      NoAssuranceMaladie: this.state.NoAssuranceMaladie,
      ExpirationAssurance_AAAA: this.state.ExpirationAssurance_AAAA,
      ExpirationAssurance_MM: this.state.ExpirationAssurance_MM,
      ProblemesSante: this.state.ProblemesSante,
      DateInscription: Date.now(),
      Renew: "2024-2025",
      DateInscription: this.today.toISOString() 
    };

    try {
      const response = await base.push('/Eleves', {
        data: eleveData
      });
      console.log('Record added with ID:', response.key);
      this.setState({ IDUser: response.key, showSuccess: true });
      console.log('++ familyRecordExists:', this.state.familyRecordExists, this.state.familyId);
      if (this.state.familyRecordExists) {
        this.updateEleveWithFamilyId(response.key, this.state.familyId); // Update Eleve with existing family ID
      } else {
        this.pushFamilyData(response.key); // Call to push family data
      }
    } catch (error) {
      console.error('Error adding record:', error);
      this.setState({ error: 'Failed to add record. Please try again.' });
    }
  };

  pushFamilyData = async (IDUser) => {
    const familyData = {
      IDUser,
      NomPere: this.state.NomPere,
      PrenomPere: this.state.PrenomPere,
      NomMere: this.state.NomMere,
      PrenomMere: this.state.PrenomMere,
      TelephoneMaison: this.state.TelephoneMaison,
      Cellulaire: this.state.Cellulaire,
      UrgenceContact: this.state.UrgenceContact,
      UrgenceCellulaire: this.state.UrgenceCellulaire,
      UrgencePhoneMaison: this.state.UrgencePhoneMaison,
      Ville: this.state.Ville,
      Rue: this.state.Rue,
      NoCivique: this.state.NoCivique,
      Appartement: this.state.Appartement,
      CodePostal: this.state.CodePostal
    };

    try {
      const familyResponse = await base.push('/Familles', {
        data: familyData
      });
      console.log('Family record added with ID:', familyResponse.key);
      this.updateEleveWithFamilyId(IDUser, familyResponse.key); // Call to update eleve with family ID
    } catch (error) {
      console.error('Error adding family record:', error);
    }
  };

  updateEleveWithFamilyId = async (eleveId, familyId) => {
    try {
      await base.update(`/Eleves/${eleveId}`, {
        data: { IdFamille: familyId }
      });
      console.log('Eleve updated with family ID');
    } catch (error) {
      console.error('Error updating eleve with family ID:', error);
    }
  };

  updateFamilyIds = async () => {
    try {
      const elevesSnapshot = await base.fetch('/Eleves', { context: this, asArray: false });
      const famillesSnapshot = await base.fetch('/Familles', { context: this, asArray: false });
  
      const eleves = elevesSnapshot;
      const familles = famillesSnapshot;
  
      const nonMatchingEleves = [];
  
      Object.keys(eleves).forEach(async (eleveKey) => {
        const eleve = eleves[eleveKey];
        const eleveLastName = eleve.nom.trim().toLowerCase();
  
        let matchingFamilyId = null;
  
        for (const familleKey of Object.keys(familles)) {
          const famille = familles[familleKey];
          if (famille.NomPere.trim().toLowerCase() === eleveLastName) {
            matchingFamilyId = familleKey;
            break; // Break the loop after finding the first match
          }
        }
  
        if (matchingFamilyId) {
          await base.update(`/Eleves/${eleveKey}`, {
            data: { IdFamille: matchingFamilyId }
          });
        } else {
         // console.log(`No matching Famille found for Eleve ID ${eleveKey} with Nom ${eleve.nom}`);
          nonMatchingEleves.push(eleve);
        }
      });
  
      // Log non-matching élèves after processing all élèves
      console.log('Eleves not associated with any famille:', nonMatchingEleves);
    } catch (error) {
      console.error('Error updating family IDs:', error);
    }
  };
  
  
  
  

  render() {
    const { step, showSuccess, error } = this.state;

    return (
      <Wrapper2>
        {error && <Message>{error}</Message>}
        {this.renderStepContent()}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          {step > 1 && (
            <SubmitButton onClick={this.prevStep}>Précédent</SubmitButton>
          )}
          {step < 7 ? (
            <SubmitButton onClick={this.nextStep} style={{ marginLeft: 'auto' }}>Suivant</SubmitButton>
          ) : (
            <SubmitButton onClick={this.handleSubmit}>Soumettre</SubmitButton>
          )}
        </div>
        {showSuccess && (
          <Confirm
            visible={true} // You can use true directly since it's already conditionally rendered
            title="Votre demande a été correctement envoyée. Nous vous contacterons après réception du paiement Interac. Souhaiteriez-vous inscrire un autre enfant ?"
            okText="Non"
            cancelText="Oui"
            onConfirm={() => {
              this.setState({ showSuccess: false });
              this.props.history.push('/');
            }}
            onCancel={this.resetForNewEntry}
          />
        )}
      </Wrapper2>
    );
  }

  resetForNewEntry = () => {
    this.setState({
      prenom: '',
      nom: '',
      step: 1,
      showSuccess: false,

      DDN_AAAA: '',
      DDN_MM: '',
      DDN_JJ: '',
      NomAncienMadrassa: '',
      LieuNaissance: '',
      NoAssuranceMaladie: '',
      ExpirationAssurance_AAAA: '',
      ExpirationAssurance_MM: '',
      ProblemesSante: this.state.ProblemesSante,

      sexe: 'Masculin',
      DejaSuiviCoursDArabe: 'Oui',
      LangueMaison: 'Arabe',
      NiveauScolairePublic: 'Prématernelle',
      NiveauScolaireIslamique: 'Débutant',

      NomPere: '',
      PrenomPere: '',
      NomMere: '',
      PrenomMere: '',
      TelephoneMaison: '',
      Cellulaire: '',
      UrgenceContact: '',
      UrgenceCellulaire: '',
      UrgencePhoneMaison: '',
      Ville: '',
      Rue: '',
      NoCivique: '',
      Appartement: '',
      CodePostal: ''
    });
    this.props.history.push('/public/add');
  };

  renderStepContent() {
    const { step } = this.state;
    switch (step) {
      case 1:
        return this.renderStep1();
      case 2:
        return this.renderStep2();
      case 3:
        return this.renderStep3();
      case 4:
        return this.renderStep4();
      case 5:
        return this.renderStep5();
      case 6:
        return this.renderStep6();
      case 7:
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
        return this.renderStep7();
      default:
        return null;
    }
  }

  renderStep1() {
    // Step 1 content <SubmitButton onClick={this.updateFamilyIds}>updateFamilyIds</SubmitButton>
    return (
      <Fragment>
        
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Nom complet de l'élève"
              placeholder='Prénom'
              value={this.state.prenom}
              handleChange={(e) => this.handleChange(e, 'prenom')}
              hideTitleOnMobile={false}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder='Nom'
              value={this.state.nom}
              handleChange={(e) => this.handleChange(e, 'nom')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
        </FlexRow>

        <FlexRow>
          <FlexColumn>
            <InputDropDown title="Sexe" options={['Masculin', 'Féminin']} value={this.state.sexe} handleChange={(e) => this.handleChange(e, 'sexe')} />
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            <DateInput
              onChange={this.handleDateChange}
              title={this.state.sexe === 'Féminin' ? "Née le" : "Né le"}
              dayPresence={true}
              naissance={true}
            />
          </FlexColumn>
        </FlexRow>

        <FlexRow>
          <FlexColumn>
            <InputText title="Lieu de naissance" placeholder='pays de naissance' value={this.state.LieuNaissance} handleChange={(e) => this.handleChange(e, 'LieuNaissance')} />
          </FlexColumn>
        </FlexRow>
      </Fragment>
    );
  }

  renderStep2() {
    // Step 2 content
    return (
      <Fragment>
        <FlexRow>
          <FlexColumn>
            <InputDropDown title="Problèmes de santé" options={['Non', 'Oui']} value={this.state.ProblemesSante} handleChange={(e) => this.handleChange(e, 'ProblemesSante')} />
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            <InputText title="Assurance maladie" placeholder="Numéro d'assurance maladie" value={this.state.NoAssuranceMaladie} handleChange={(e) => this.handleChange(e, 'NoAssuranceMaladie')} />
          </FlexColumn>
        </FlexRow>

        <FlexRow>
          <FlexColumn>
            <DateInputAssurance
              onChange={this.handleDateChange}
              title={"Expiration"}
              dayPresence={false}
              naissance={false} />
          </FlexColumn>
        </FlexRow>
      </Fragment>
    );
  }

  renderStep3() {
    // Step 3 content
    return (
      <Fragment>
        <InputDropDown title="A déjà suivi des cours d'arabe?" options={['Oui', 'Non']} value={this.state.DejaSuiviCoursDArabe} handleChange={(e) => this.handleChange(e, 'DejaSuiviCoursDArabe')} />
        <InputDropDown title="Langue parlée à la maison" options={['Arabe', 'Kabyle', 'Francais', 'Anglais', 'Turc', 'Autres']} value={this.state.LangueMaison} handleChange={(e) => this.handleChange(e, 'LangueMaison')} />
        <InputDropDown
          title="Niveau scolaire en public"
          options={[
            'Prématernelle',
            'maternelle',
            '1re année',
            '2e année',
            '3e année',
            '4e année',
            '5e année',
            '6e année',
            '1re secondaire',
            '2e secondaire',
            '3e secondaire',
            '4e secondaire',
            '5e secondaire'
          ]}
          value={this.state.NiveauScolairePublic}
          handleChange={(e) => this.handleChange(e, 'NiveauScolairePublic')}
        />
        <InputDropDown title="Niveau scolaire islamique" options={['Débutant', 'Moyen', 'Avancé']} value={this.state.NiveauScolaireIslamique} handleChange={(e) => this.handleChange(e, 'NiveauScolaireIslamique')} />
        <InputText title="Nom de l'ancienne école de coran" value={this.state.NomAncienMadrassa} handleChange={(e) => this.handleChange(e, 'NomAncienMadrassa')} />
      </Fragment>
    );
  }

  renderStep4() {
    // Step 4 content
    return (
      <Fragment>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Nom complet du père"
              placeholder='Prénom'
              value={this.state.PrenomPere}
              handleChange={(e) => this.handleChange(e, 'PrenomPere')}
              hideTitleOnMobile={false}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder='Nom'
              value={this.state.NomPere}
              handleChange={(e) => this.handleChange(e, 'NomPere')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
        </FlexRow>

        <FlexRow>
          <FlexColumn>
            <InputText
              title="Nom complet de la mère"
              placeholder='Prénom'
              value={this.state.PrenomMere}
              handleChange={(e) => this.handleChange(e, 'PrenomMere')}
              hideTitleOnMobile={false}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder='Nom'
              value={this.state.NomMere}
              handleChange={(e) => this.handleChange(e, 'NomMere')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Contact d'urgence"
              placeholder='Nom complet du contact '
              value={this.state.UrgenceContact}
              handleChange={(e) => this.handleChange(e, 'UrgenceContact')}
            />
          </FlexColumn>
        </FlexRow>
      </Fragment>
    );
  }
  
  renderStep5() {
    // Step 5 content
    return (
      <Fragment>
        <FlexRow>
          <FlexColumn>
            <CustomPhoneInput value={this.state.Cellulaire} onChange={this.handlePhoneChange} text={"Cellulaire"} />
          </FlexColumn>
          <FlexColumn>
            <CustomPhoneInput value={this.state.TelephoneMaison} onChange={this.handleTelephoneMaisonChange} text={"Téléphone de la Maison"} />
          </FlexColumn>
          <FlexColumn>
            <CustomPhoneInput value={this.state.UrgenceCellulaire} onChange={this.handleUrgenceCellulaireChange} text={"Cellulaire d'urgence"} />
          </FlexColumn>
        </FlexRow>
      </Fragment>
    );
  }

  renderStep6() {
    // Step 6 content
    return (
      <Fragment>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Adresse"
              placeholder="Numéro civique"
              value={this.state.NoCivique}
              handleChange={(e) => this.handleChange(e, 'NoCivique')}
              hideTitleOnMobile={false}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder="Numéro d'appartement"
              value={this.state.Appartement}
              handleChange={(e) => this.handleChange(e, 'Appartement')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder='Rue'
              value={this.state.Rue}
              handleChange={(e) => this.handleChange(e, 'Rue')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder="CodePostal"
              value={this.state.CodePostal}
              handleChange={(e) => this.handleChange(e, 'CodePostal')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder="Ville"
              value={this.state.Ville}
              handleChange={(e) => this.handleChange(e, 'Ville')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
        </FlexRow>
      </Fragment>
    );
  }

  renderStep7() {
    const { amount, showModal, error, loading } = this.state;
    return (
      <Fragment>
        {this.state.loading && <LottieAnimation options={this.defaultOptions} height={120} width={120} />}
        {!this.state.loading && (
          <FlexRow>
            <FlexColumn>
              <divx style={{ fontFamily: "'Gatwick', sans-serif", color: color.colorA }}>
                <img src={`/img/Interac.png`} alt="Virement Interac" style={{ maxWidth: '100%', height: 'auto' }} />
                <h2 style={{ marginLeft: "30px" }}>Frais de scolarité avec matériel didactique inclus:</h2>
                <p style={{ marginLeft: "30px" }}><strong>Un enfant</strong> : 550$ (Ou en 3 versements : 350$ avant la rentrée + 100$ à l'hiver + 100$ au printemps).</p>
                <p style={{ marginLeft: "30px" }}><strong>Deux enfants</strong> : 1000$ (Ou en 3 versements : 500$ avant la rentrée + 250$ à l'hiver + 250$ au printemps).</p>
                <p style={{ marginLeft: "30px" }}><strong>Trois enfants</strong> : 1500$ (Ou en 3 versements : 800$ avant la rentrée + 350$ à l'hiver + 350$ au printemps).</p>
                <p style={{ marginLeft: "30px" }}><strong>Quatre enfants</strong> : 2000$ (Ou en 3 versements : 1200$ avant la rentrée + 400$ à l'hiver + 400$ au printemps).</p>
                <p style={{ marginLeft: "30px" }}>Veuillez effectuer un virement à <strong>ecole.les.compagnons@gmail.com</strong></p>
                <p style={{ marginLeft: "30px" }}>Nous vous informons que votre inscription sera validée dès réception du paiement.</p>
                <p style={{ textAlign: 'right' }}>- La direction</p>
              </divx>
            </FlexColumn>
          </FlexRow>
        )}
      </Fragment>
    );
  }
}

export default AddProduct;
