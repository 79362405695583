import React, { useState } from 'react';
import Modal from '../../components/utility/Modal';
import styled from 'styled-components';
import * as color from '../../color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import Lottie from 'react-lottie';
import animationData from '../public/Loading.json';

const LinkIcon = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorA};
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s;
  font-size: 32px;
  &:hover {
    color: ${color.colorA};
  }
  @media (max-width: 700px) {
    margin: 4px;
    font-size: 24px;
  }
`;

const Wrapper2 = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: White;
  border-radius: 5px;
  max-width: 650px;
  width: 100%;
  margin: 20px auto;
  overflow: visible;
  @media (max-width: 768px) {
    padding: 15px;
    width: 95vw;
    margin: 15px auto;
  }
`;

const StyledDeleteProduct = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorR};
  font-size: 18px;
  transition: color 0.2s;
  cursor: pointer;
  @media (max-width: 700px) {
    margin: 4px;
    font-size: 18px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const CheckboxLabel = styled.label`
  margin-bottom: 10px;
`;

const LottieAnimation = styled(Lottie)`
  width: 60%;
  margin: 10px auto;
`;

const calculateAge = (year, month, day) => {
  const today = new Date();
  const birthDate = new Date(year, month - 1, day); // month - 1 because months are zero-indexed
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const Export = ({ isOpen, onRequestClose, children, products }) => {
  const [onlyApproved, setOnlyApproved] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleCheckboxChange = () => {
    setOnlyApproved(!onlyApproved);
  };

  const handleSendClick = () => {
    setLoading(true);
    setTimeout(() => {
      // Filter products based on checkboxes
      let filteredProducts = products;
      if (onlyApproved) {
        filteredProducts = filteredProducts.filter(product => product.Approved === 'Yes');
      }

      // Separate products into "Filles" and "Garçons"
      const filles = filteredProducts.filter(product => product.sexe === 'F');
      const garcons = filteredProducts.filter(product => product.sexe === 'M');

      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Convert data to worksheet format and add age correction
      const addAgeCorrection = (products) => {
        return products.map(product => {
          const age = calculateAge(product.DDN_AAAA, product.DDN_MM, product.DDN_JJ);
          if (age < 1 || isNaN(age)) {
            return { ...product, Correction: 'Anouar Note: Age doit etre corriger par le parent' };
          } else {
            return { ...product, Correction: age };
          }
        });
      };

      const addRedTextStyle = (worksheet, data) => {
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const cellAddress = XLSX.utils.encode_cell({ c: range.e.c, r: R });
          if (worksheet[cellAddress] && worksheet[cellAddress].v === 'Anouar Note: Age doit etre corriger par le parent') {
            worksheet[cellAddress].s = {
              font: {
                color: { rgb: "FF0000" }
              }
            };
          }
        }
      };

      const worksheetFilles = XLSX.utils.json_to_sheet(addAgeCorrection(filles));
      addRedTextStyle(worksheetFilles, filles);

      const worksheetGarcons = XLSX.utils.json_to_sheet(addAgeCorrection(garcons));
      addRedTextStyle(worksheetGarcons, garcons);

      // Count by age
      const ageCounts = {};
      const correctionCount = { 'Age doit etre corriger': 0 };
      filteredProducts.forEach(product => {
        const age = calculateAge(product.DDN_AAAA, product.DDN_MM, product.DDN_JJ);
        if (age < 1 || isNaN(age)) {
          correctionCount['Age doit etre corriger'] += 1;
        } else {
          if (age in ageCounts) {
            ageCounts[age] += 1;
          } else {
            ageCounts[age] = 1;
          }
        }
      });

      // Create Count sheet data
      const countData = [
        { Category: 'Filles', Count: filles.length },
        { Category: 'Garçons', Count: garcons.length },
        ...Object.keys(ageCounts).map(age => ({ Category: `Age ${age}`, Count: ageCounts[age] })),
        { Category: 'Age doit etre corriger', Count: correctionCount['Age doit etre corriger'] }
      ];
      const worksheetCount = XLSX.utils.json_to_sheet(countData);

      // Add worksheets to workbook
      XLSX.utils.book_append_sheet(workbook, worksheetFilles, 'Filles');
      XLSX.utils.book_append_sheet(workbook, worksheetGarcons, 'Garçons');
      XLSX.utils.book_append_sheet(workbook, worksheetCount, 'Count');

      // Write workbook to a file
      XLSX.writeFile(workbook, 'products.xlsx');

      setLoading(false);
      onRequestClose();
    }, 4000); // Show loading animation for 4 seconds
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <React.Fragment>
      <StyledDeleteProduct onClick={() => {}}>
        {children}
      </StyledDeleteProduct>
      <Modal
        close
        visible={isOpen}
        title="Export"
        handleModal={onRequestClose}
      >
        <Wrapper2>
          {loading ? (
            <LottieAnimation options={defaultOptions} height={120} width={120} />
          ) : (
            <div>
              <CheckboxContainer>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    checked={onlyApproved}
                    onChange={handleCheckboxChange}
                  />
                  Only approved
                </CheckboxLabel>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    checked={!onlyApproved}
                    onChange={handleCheckboxChange}
                  />
                  Tout le monde
                </CheckboxLabel>
              </CheckboxContainer>
              <LinkIcon onClick={handleSendClick}>
                <FontAwesomeIcon icon={faCircleArrowRight} size="2x" style={{ color: color.olimpico }} />
              </LinkIcon>
            </div>
          )}
        </Wrapper2>
      </Modal>
    </React.Fragment>
  );
};

Export.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  children: PropTypes.node
};

export default Export;
