import React from 'react';
import styled from 'styled-components';
import * as color from '../../../color';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.span`
  font-size: 16px;
  color: ${color.colorBlack};
  @media (max-width: 700px) {
    display: ${props => props.hideOnMobile ? 'none' : 'inline-block'};
  }
`;

const Title = ({ title, hideOnMobile, children }) => (
  <TitleContainer>
    <TitleText hideOnMobile={hideOnMobile}>{title}</TitleText>
    {children}
  </TitleContainer>
);

export default Title;
