import React from 'react';
import firebase from 'firebase';
import base from '../firebase';

import HeaderBar from '../components/HeaderBar';
import AuthBox from './public/AuthBox';
import StyledLink from '../components/utility/StyledLink';
import { DropDown, DropDownItem } from '../components/utility/DropDown';
import SupervisorAccountSharp from '@material-ui/icons/SupervisorAccountSharp';
import ViewList from '@material-ui/icons/ViewList';
import Assignment from '@material-ui/icons/Assignment';
import Create from '@material-ui/icons/AddCircleOutline';
import Logout from '@material-ui/icons/KeyboardArrowLeft';
import Class from '@material-ui/icons/LibraryBooks';


import * as color from '../color';

class Header extends React.Component {
  state = {
    visible: false,
    familles: [],
    filteredFamilleList: [],
    title: '',
    user: null,
  };

  handleMenu = () => this.setState({ visible: !this.state.visible });

  componentDidMount() {
    base.fetch('/Familles', {
      context: this,
      asArray: true,
    })
      .then((data) => {
        this.setState({
          familles: data,
          sortBy: 'dateNewToOld',
          user: this.props.user,
        });
      })
      .catch((error) => console.log(error));
  }

  logout = () => {
    localStorage.removeItem('user');
    firebase.auth().signOut().then(() => {
      this.props.updateUser(null);
      this.props.history.push('/');
    }).catch((error) => {
      console.error('Sign Out Error', error);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user || prevState.familles !== this.state.familles) {
      this.filteredUser();
    }
  }

  normalizePhoneNumber = (number) => {
    const digits = number.replace(/\D/g, '');
    return digits.startsWith('1') ? `+${digits}` : `+1${digits}`;
  }

  filteredUser = () => {
    if (this.state.user) {
      const userPhoneNumber = this.normalizePhoneNumber(this.props.user.phoneNumber);
      const matchingFamille = this.state.familles.find((famille) => {
        return [
          famille.TelephoneMaison,
          famille.UrgenceCellulaire,
          famille.UrgenceContact,
          famille.UrgencePhoneMaison,
          famille.Cellulaire,
        ].some((phone) => phone && this.normalizePhoneNumber(phone) === userPhoneNumber);
      });

      if (matchingFamille) {
        this.setState({
          title: `${matchingFamille.PrenomPere} ${matchingFamille.NomPere}`,
        });
      }
    }
  };

  handleClearRecaptcha = (clearFunc) => {
    this.clearRecaptchaFunction = clearFunc;
  };

  checkUserProfiles = () => {
    const { familles } = this.state;
    if (!this.props.user || !this.props.user.phoneNumber) {
      return { isStudent: false, isAdmin: false, isRestricted: false };
    }
    const normalizedPhoneNumber = this.normalizePhoneNumber(this.props.user.phoneNumber);
    const isStudent = familles.some((famille) => [
      this.normalizePhoneNumber(famille.TelephoneMaison),
      this.normalizePhoneNumber(famille.UrgenceCellulaire),
      this.normalizePhoneNumber(famille.UrgencePhoneMaison),
    ].includes(normalizedPhoneNumber));
    const isAdmin = [
      '+15146532079', "+15145770596"
    ].includes(normalizedPhoneNumber);
    const isRestricted = [
      '+15144629373', '+14384101920', '+15146913866', '+15142613284',
      '+15145527060', '+14382828612', '+14382201778',
    ].includes(normalizedPhoneNumber);

    return { isStudent, isAdmin, isRestricted };
  };

  render() {
    const { isStudent, isAdmin, isRestricted } = this.checkUserProfiles();
    return (
      <HeaderBar>
        {this.state.title.length > 0 ? (
          <React.Fragment>
            <DropDown
              handleMenu={this.handleMenu}
              title={this.state.title || 'Bienvenue'}
              visible={this.state.visible}
            >
              <DropDownItem onClick={this.handleMenu}>
                <StyledLink to="/user/product-list" style={{ display: 'flex', alignItems: 'center' }}>
                  <SupervisorAccountSharp style={{ fontSize: 30, color: color.olimpico, marginRight: 8 }} />
                  Élèves
                </StyledLink>
              </DropDownItem>

              {(isAdmin || isRestricted) && (
                <DropDownItem onClick={this.handleMenu}>
                  <StyledLink to="/user/classes-list" style={{ display: 'flex', alignItems: 'center' }}>
                    <Class style={{ fontSize: 30, color: color.olimpico, marginRight: 8 }} />
                    Rapports</StyledLink>
                </DropDownItem>
              )}

              {(isAdmin || isRestricted) && (
                <DropDownItem onClick={this.handleMenu}>
                  <StyledLink to="/user/chart" style={{ display: 'flex', alignItems: 'center' }}>
                    <ViewList style={{ fontSize: 30, color: color.olimpico, marginRight: 8 }} />
                    Absences</StyledLink>
                </DropDownItem>
              )}
              {(isAdmin || isRestricted) && (
                <DropDownItem onClick={this.handleMenu}>
                  <StyledLink to="/user/devoir" style={{ display: 'flex', alignItems: 'center' }}>
                    <Assignment style={{ fontSize: 30, color: color.olimpico, marginRight: 8 }} />
                    Devoirs</StyledLink>
                </DropDownItem>
              )}




              <DropDownItem onClick={this.handleMenu}>
                <StyledLink to="/public/add" style={{ display: 'flex', alignItems: 'center' }}>
                  <Create style={{ fontSize: 30, color: color.olimpico, marginRight: 8 }} />
                  Nouveau</StyledLink>
              </DropDownItem>

              <DropDownItem onClick={this.logout} style={{ display: 'flex', alignItems: 'center' }}>
              <Logout style={{ fontSize: 30, color: color.olimpico, marginRight: 8 }} />
                Sortir</DropDownItem>
            </DropDown>
          </React.Fragment>
        ) : (
          <AuthBox familias={this.state.familles} updateUser={this.props.updateUser} />
        )}
      </HeaderBar>
    );
  }
}

export default Header;