import React from 'react';
import FilterItem from '../FilterItem';
import FilterCategoryItem from './FilterCategoryItem';
import FilterSubcategory from './FilterSubcategory';
import Modal from '../../../utility/Modal';
import styled from 'styled-components';

const FilterCategoryBox = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

// render category list
const filterCategoryList = props => {
  const allCategory = Object.keys(props.category).map(

    (categoryTitle, index) => (

      <FilterCategoryItem
        key={index + 1}
        categoryTitle={categoryTitle}
        icon="ion-md-add"
        handleSubcategory={props.handleSubcategory}
      />
    )

  );


  return (
    <React.Fragment>
      <FilterCategoryItem
        key={0}
        categoryTitle={'Toutes les classes'}
        handleSubcategory={props.handleSubcategory}
        handleModal={props.handleModal}
        icon="ion-md-home"
      />
      {allCategory}
    </React.Fragment>
  );
};

const FilterCategory = props => (
  <React.Fragment>
    <FilterItem icon="ion-md-pricetags" handleModal={props.handleModal}>
      {props.currentCategory
        ? `${props.currentCategory}`
        : 'Classe'}
    </FilterItem>


    <Modal
      close
      visible={props.visible}
      title="Classes"
      handleModal={props.handleModal}
    >
      <FilterCategoryBox>
        <div>
          {Object.keys(props.category).length > 0
            ? filterCategoryList(props)
            : 'Chargement des classes..'}
        </div>
        <div>
          {props.subcategory.length > 0 && (

            <FilterSubcategory
              handleModal={props.handleModal}
              handleCategory={props.handleCategory}
              subcategory={props.subcategory}
              handleSubcategory={props.handleSubcategory}
              currentCategory={props.currentCategory}  
              categoryTitle={props.categoryTitle}
            />

          )}
        </div>
      </FilterCategoryBox>
    </Modal>
  </React.Fragment>
);

export default FilterCategory;
