import React, { useState, useEffect } from 'react';
import base from '../../firebase';
import Modal from '../../components/utility/Modal';
import styled from 'styled-components';
import * as color from '../../color';
import Lottie from 'react-lottie';
import animationData from '../public/Loading.json';
import DevoirList from './DevoirList';
import DescriptionList from './DescriptionList';


const Wrapper2 = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  border-radius: 5px;
  max-width: 650px;
  width: 100%;
  margin: 20px auto;
  overflow: visible;

  @media (max-width: 768px) {
    padding: 15px;
    width: 95vw;
    margin: 15px auto;
  }
`;

const StyledDeleteProduct = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorR};
  font-size: 18px;
  transition: color 0.2s;
  cursor: pointer;

  @media (max-width: 700px) {
    margin: 4px;
    font-size: 18px;
  }
`;

const LottieAnimation = styled(Lottie)`
  width: 60%;
  margin: 10px auto;
  height: 400px;
`;

const ScrollableContent = styled.div`
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto;
`;

const NotificationComponent = ({ pid, children, handleBadge }) => {
  const [visible, setVisible] = useState(false);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  


  const fetchDescriptionDetails = pid => {
    base.fetch(`/Eleves/${pid}`, {
      context: this,
      then(data) {
        setDescription(data.description);
        
        
      }
    }).catch(error => {
      console.error("Error fetching data: ", error);
    });
    
  }

  const fetchDevoirs = async () => {
    try {
      const data = await base.fetch('/Devoirs', {
        context: this,
        asArray: true
      });
      setNotifications(data);
     // handleBadge(data.length); // Update the badge count
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVisibility = () => {
    setVisible(true);
    setLoading(true);
    fetchDescriptionDetails(pid);
    fetchDevoirs();
  };

  const handleCancel = () => setVisible(false);

  return (
    
    <React.Fragment>
      <StyledDeleteProduct onClick={handleVisibility}>
        {children}
      </StyledDeleteProduct>
      <Modal
        close
        visible={visible}
        title="Notifications"
        handleModal={handleCancel}
      >
        <Wrapper2>
          {loading ? (
            <LottieAnimation options={defaultOptions} height={120} width={120} />
          ) :  (
            <ScrollableContent>
              <DevoirList devoirs={notifications} />
              {description && <DescriptionList description={description} />}
            </ScrollableContent>
          )}
        </Wrapper2>
      </Modal>
    </React.Fragment>
  );
};

export default NotificationComponent;
