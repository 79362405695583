import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InputDropDown from '../../components/user/Form/InputDropDown';

const DateInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 620px;
  width: 100%;
  margin: auto;

  @media (max-width: 700px) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 100%;
  }
`;

const FlexItem = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 700px) {
    &:not(:last-child) {
      margin-right: 2%;
    }
    flex: 0 0 30%;
  }
`;

class DateInput extends React.Component {
  constructor(props) {
    super(props);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const currentDay = new Date().getDate();

    this.state = {
      year: props.annee || currentYear,
      month: props.mois || currentMonth,
      day: props.jour || currentDay,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.year !== this.state.year ||
      prevState.month !== this.state.month ||
      prevState.day !== this.state.day
    ) {
      this.props.onChange({
        year: this.state.year,
        month: this.state.month,
        day: this.state.day,
      });
    }
  }

  handleYearChange = (e) => {
    this.setState({ year: parseInt(e.target.value, 10) });
  };

  handleMonthChange = (e) => {
    const monthIndex = this.months.indexOf(e.target.value) + 1;
    this.setState({ month: monthIndex });
  };

  handleDayChange = (e) => {
    this.setState({ day: parseInt(e.target.value, 10) });
  };

  get years() {
    const currentYear = new Date().getFullYear();
    return this.props.naissance
      ? Array.from({ length: 20 }, (_, i) => (currentYear - i).toString())
      : Array.from({ length: 20 }, (_, i) => (currentYear + i).toString());
  }

  get months() {
    return Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('fr', { month: 'long' }));
  }

  get days() {
    return Array.from({ length: 31 }, (_, i) => (i + 1).toString());
  }

  render() {
    return (
      <DateInputContainer>
        {this.props.dayPresence && (
          <FlexItem>
            <InputDropDown
              title={this.props.title}
              options={this.props.jour ? [this.props.jour.toString()] : this.days}
              value={this.state.day.toString()}
              handleChange={this.handleDayChange}
            />
          </FlexItem>
        )}
        <FlexItem>
          <InputDropDown
            title="&#160;"
            options={this.props.mois ? [this.months[this.props.mois - 1]] : this.months}
            value={this.props.mois ? this.months[this.props.mois - 1] : this.months[this.state.month - 1]}
            handleChange={this.handleMonthChange}
          />
        </FlexItem>
        <FlexItem>
          <InputDropDown
            title="&#160;"
            options={this.props.annee ? [this.props.annee.toString()] : this.years}
            value={this.state.year.toString()}
            handleChange={this.handleYearChange}
          />
        </FlexItem>
      </DateInputContainer>
    );
  }
}

DateInput.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dayPresence: PropTypes.bool,
  naissance: PropTypes.bool,
  annee: PropTypes.number,
  mois: PropTypes.number,
  jour: PropTypes.number,
};

export default DateInput;
