import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import InputDropDown from '../../components/user/Form/InputDropDownCalendar';

const DateInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 620px; // Good for desktop
  width: 100%; // Adjust width as a percentage of the viewport
  margin: auto;

  @media (max-width: 700px) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 100%; // Use full width of the viewport on mobile
  }
`;


const FlexItem = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 10px; // Keep some space between on desktop
  }

  @media (max-width: 700px) {
    &:not(:last-child) {
      margin-right: 2%; // Less margin to save space
    }
    flex: 0 0 50%; // Adjust this basis if still too large
  }
`;


function DateInput({ onChange, title, dayPresence, naissance }) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [day, setDay] = useState(new Date().getDate());
  const [daysInMonth, setDaysInMonth] = useState(31);

  useEffect(() => {
    const newDaysInMonth = new Date(year, month + 1, 0).getDate();
    setDaysInMonth(newDaysInMonth);
    if (day > newDaysInMonth) setDay(newDaysInMonth);
    onChange({ year, month, day }); // Communicate back the changes
  }, [year, month, day, onChange]);
  let years = 0;
  if (naissance) { years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - i); }
  else { years = Array.from({ length: 20 }, (_, i) => new Date().getFullYear() + i); }



  const months = Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('fr', { month: 'long' }));
  const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];


  return (
    <DateInputContainer>
      {dayPresence ? (
        <FlexItem>
          <InputDropDown
            title={title}
            options={days}
            value={day}
            handleChange={e => setDay(parseInt(e.target.value, 10))}
          />
        </FlexItem>
      ) : (
        <div></div>
      )}
      {dayPresence ? (
      <FlexItem>
        <InputDropDown
          title="&#160;"
          options={months}
          value={month}
          handleChange={e => setMonth(e.target.value, 10)}
        />
      </FlexItem>
      ) : (
        <FlexItem>
        <InputDropDown
          title={title}
          options={months}
          value={month}
          handleChange={e => setMonth(e.target.value, 10)}
        />
      </FlexItem>
      )}
      <FlexItem>
        <InputDropDown
          title="&#160;"
          options={years}
          value={year}
          handleChange={e => setYear(parseInt(e.target.value, 10))}
        />
      </FlexItem>
      
      

    </DateInputContainer>


  );
}

export default DateInput;


