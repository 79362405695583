import React, {Fragment} from 'react';
import base from '../../firebase';

import InputDropDown from '../../components/user/Form/InputDropDown';
import SubmitButton from '../../components/user/Form/SubmitButton';
import StyledLink from '../../components/utility/StyledLink';
import styled from 'styled-components';


const Wrapper2 = styled.div`
  padding: 20px; // Uniform padding around all sides
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: White;
  border-radius: 5px;
  max-width: 650px;
  width: 100%;
  margin: 20px auto; // Adds margin on the top and bottom as well
  overflow: visible;

  @media (max-width: 768px) {
    padding: 15px; // Slightly less padding on mobile for more space efficiency
    width: 95vw; // Adjusts width to be responsive
    margin: 15px auto; // Less margin on mobile
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: calc(100% - 20px); // Adjust width to account for padding

  @media (max-width: 700px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const FlexColumn = styled.div`
  flex: 1;
  &:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 700px) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

class Renew extends React.Component {
  state = {
    Renew: 'Oui'
  };

  componentDidMount() {
    this.setStateInfo();
  }

  handleRenewChange = (e) => {
    this.setState({ Renew: e.target.value });
  };

  setStateInfo = () => {
    if (this.props.productList.length > 0) {
      const product = this.props.productList.find(
        item => item.eleveKey === this.props.match.params.eleveKey
      );
      if (product) {
        this.setState({ ...product });
      }
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    const { eleveKey, familleKey } = this.props.match.params;

    if (this.state.Renew === 'Oui') {
      const today = new Date();
      base.update(`/Eleves/${eleveKey}`, {
        data: {
          Renew: "2024-2025",
          DateInscription: today.toISOString()  // Storing date in ISO format
        }
      }).then(() => {
        this.props.history.push(`/user/edit/${eleveKey}/${familleKey}`);
      }).catch(error => {
        console.log('Error updating the renewal:', error);
      });
    } else if (this.state.Renew === 'Non') {
      this.props.history.push('/user/product-list');
    }
  };

  render() {
    return (
      <Wrapper2>
        {this.props.match.params.eleveKey ? (
          <Fragment>
            <FlexRow>
          <FlexColumn>
            <InputDropDown 
              title="Souhaitez-vous renouveler l'inscription?" 
              options={['Oui', 'Non']} 
              value={this.state.Renew} 
              handleChange={this.handleRenewChange}
            />
</FlexColumn> </FlexRow>
            <SubmitButton onClick={this.handleSubmit}>OK</SubmitButton>
          </Fragment>
        ) : (
          <StyledLink to="/user/product-list">ANNULER</StyledLink>
        )}
      </Wrapper2>
    );
  }
}

export default Renew;
