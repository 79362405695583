import React, { Component } from 'react';
import base from '../firebase';

import Products from '../containers/public/Products';


class Public extends Component {
  state = {
    products: [],
    filteredProductList: [],
    search: '',
    location: '',
    category: '',
    sortBy: ''
  };

  componentDidMount() {
    
    base
      .fetch('/events', {
        context: this,
        asArray: true
      })
      .then(data => {
        // Assuming `date` is the property that holds the date information
        const sortedData = data.sort((a, b) => {
          // Convert dates to timestamps for comparison if they're not already timestamps
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
  
          return dateA - dateB; // For ascending order
        });
  
        this.setState({
          products: sortedData,
          filteredProductList: sortedData,
          sortBy: 'dateOldToNew'
        });
      })
      .catch(error => console.log(error));
  }
  
  componentDidUpdate(prevProps, prevState) {
    // If user info or familles array has changed, update the filtered list
    if (prevProps.user !== this.props.user ) {
      //this.filteredUser();

    }
  }
  

  render() {
    return (
      <React.Fragment>
        
        {this.state.products.length > 0 && (
          <Products filteredProduct={this.state.filteredProductList}  updateUser={this.props.updateUser}/>
        )}
      </React.Fragment>
    );
  }
}

export default Public;
