import React from 'react';
import styled from 'styled-components';
import * as color from '../../../color';

const SortByBoxx = styled.div`
  background-color: white;
  border: 1px solid ${color.colorB};
  border-radius: 100px;
  padding: 5px;
  flex: 1;
  margin-right: 5px;
  @media (max-width: 700px) {
    padding: 0;
  }
`;

const SortByBox = styled.button`
  display: block;
  font-size: 16px;
  color: ${color.colorB};
  background-color: white;
  text-decoration: none;
  border-radius: 100px;
  border: 1px solid ${color.colorB};
  padding: 5px;
  text-transform: capitalize;
  cursor: pointer;
  flex: 1;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 7px;
  @media (max-width: 700px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

const SortBySelect = styled.select`
  font-size: 16px;
  color: ${color.colorB};
  background-color: white;
  border: 1px solid white;
  text-decoration: none;
  appearance: none;
  cursor: pointer;
  &:focus {
    outline: none;
  };
  margin-right: 5px;
`;

const Icon = styled.i`
  color: ${color.colorB};
  font-size: 16px;
  line-height: 0;
  vertical-align: baseline;
  margin-right: 5px;
`;

const SortBy = (props) => {
  return (
    
    <SortByBox>
      <Icon className="ion-md-funnel" />
      <SortBySelect value={props.currentSortBy} onChange={props.handleSortBy}>
        <option value="2024/2025">
          2024/2025 {props.currentSortBy === '2024/2025' ? `(${props.count})` : ''}
        </option>

        <option value="Approved">
          Approuvés {props.currentSortBy === 'Approved' ? `(${props.count})` : ''}
        </option>
        <option value="2024">
          {'<= 2024'} {props.currentSortBy === '2024' ? `(${props.count})` : ''}
        </option>
      </SortBySelect>
    </SortByBox>
  );
};

export default SortBy;
