import React from 'react';
import base from '../../firebase';
import { withRouter } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Confirm from '../../components/utility/Confirm';
import styled from 'styled-components';
import * as color from '../../color';

const StyledDeleteProduct = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorR};
  font-size: 18px;
  transition: color 0.2s;
  cursor: pointer; // Change cursor to indicate it's clickable
  @media (max-width: 700px) {
    margin: 4px;
    font-size: 18px;
  }
`;


class Approve extends React.Component {
  state = { visible: false, Approved: '' };

  handleConfirm = pid => {
    base.update(`/Eleves/${pid}`, {
      data: { Approved: 'Yes' }
    })
    .then(() => {
      this.setState({ visible: false }); // Fermer le popup immédiatement après la mise à jour
      this.props.onApprovedChange(); // Appeler la prop callback pour notifier le composant parent
    })
    .catch(error => console.log(error));
  };
  

  handleApprovedChange = event => {
    const value = event.target.value;
    const valueLength = value.length;
    if (valueLength <= 1500) {
      this.setState({
        Approved: value,

      });
    }
  };

  handleChange = (event, name) => {
    let { value } = event.target;

    this.setState({ [name]: value });
  };


  ///



  handleCancel = () => this.setState({ visible: false });

  handleVisibility = () => this.setState({ visible: true });

  render() {
    const { pid, children, style } = this.props;
    return (
      <React.Fragment>
        <StyledDeleteProduct onClick={this.handleVisibility}>
          {children}
        </StyledDeleteProduct>
        <Confirm
          visible={this.state.visible}
          title="Êtes-vous certain que vous voulez valider l'inscription de cet élève?"
          okText="Oui"
          cancelText="Non"
          onConfirm={() => this.handleConfirm(this.props.pid)}
          onCancel={this.handleCancel}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Approve);
