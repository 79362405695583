import React, { Fragment } from 'react';
import styled from 'styled-components';
import base from '../../firebase';
import InputText from '../../components/user/Form/InputText';
import InputDropDown from '../../components/user/Form/InputDropDown';
import SubmitButton from '../../components/user/Form/SubmitButton';
import Message from '../../components/user/Form/Message';
import Wrapper from './Wrapper';
import DateInput from '../public/DateInput';
import DateInputAssurance from '../public/DateInputAssuranceMaladie';
import 'react-phone-number-input/style.css';
import CustomPhoneInput from '../public/StyledPhoneInput';
import Info from '../../components/utility/Info';
import Confirm from '../../components/utility/Confirm';
import Lottie from 'react-lottie';
import animationData from '../../containers/public/Loading.json';
import Tip from './Tip';
import Box from './Box';

const Wrapper2 = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: White;
  border-radius: 5px;
  max-width: 650px;
  width: 100%;
  margin: 20px auto;
  overflow: visible;

  @media (max-width: 768px) {
    padding: 15px;
    width: 95vw;
    margin: 15px auto;
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: calc(100% - 20px);

  @media (max-width: 700px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const FlexColumn = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 700px) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const LottieAnimation = styled(Lottie)`
  width: 60%;
  margin: 10px auto;
`;

class EditProduct extends React.Component {
  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  state = {
    step: 1,
    prenom: '',
    nom: '',
    DDN_AAAA: '',
    DDN_MM: '',
    DDN_JJ: '',
    sexe: 'Masculin', // Default value
    DejaSuiviCoursDArabe: 'Oui',
    LangueMaison: 'Arabe',
    NiveauScolairePublic: 'Prématernelle',
    NiveauScolaireIslamique: 'Débutant',
    NomAncienMadrassa: '',
    LieuNaissance: '',
    NoAssuranceMaladie: '',
    ExpirationAssurance_AAAA: '',
    ExpirationAssurance_MM: '',
    ProblemesSante: 'Non',
    NomPere: '',
    PrenomPere: '',
    NomMere: '',
    PrenomMere: '',
    TelephoneMaison: '',
    Cellulaire: '',
    UrgenceContact: '',
    UrgenceCellulaire: '',
    UrgencePhoneMaison: '',
    Ville: '',
    Rue: '',
    NoCivique: '',
    Appartement: '',
    CodePostal: '',
    IDUser: '',
    showSuccess: false,
    showBox: true,
    error: '',
    loading: true
  };

  formatPhoneNumber(input) {
    let digits = input.replace(/\D/g, '');

    if (digits.length === 10) {
      digits = '1' + digits;
    }

    if (digits.startsWith('1') && digits.length === 11) {
      return `+${digits}`;
    } else {
      console.error('Invalid input format');
      return input;
    }
  }

  componentDidMount() {
    const { match, productList, familleList } = this.props;
    const { eleveKey, familleKey } = match.params;

    const eleve = productList.find(item => item.key === eleveKey);
    if (eleve) {
      if (eleve.Cellulaire) {
        eleve.Cellulaire = this.formatPhoneNumber(eleve.Cellulaire);
      }
      if (eleve.TelephoneMaison) {
        eleve.TelephoneMaison = this.formatPhoneNumber(eleve.TelephoneMaison);
      }
      if (eleve.UrgenceCellulaire) {
        eleve.UrgenceCellulaire = this.formatPhoneNumber(eleve.UrgenceCellulaire);
      }

      // Map 'M' and 'F' to 'Masculin' and 'Féminin'
      const sexe = eleve.sexe === 'M' ? 'Masculin' : 'Féminin';
      this.setState({
        ...eleve,
        sexe: sexe,
        DDN_AAAA: eleve.DDN_AAAA,
        DDN_MM: eleve.DDN_MM,
        DDN_JJ: eleve.DDN_JJ
      });
    } else {
      console.log('No matching');
    }

    const famille = familleList.find(item => item.IDUser === familleKey);
    if (famille) {
      if (famille.Cellulaire) {
        famille.Cellulaire = this.formatPhoneNumber(famille.Cellulaire);
      }
      if (famille.TelephoneMaison) {
        famille.TelephoneMaison = this.formatPhoneNumber(famille.TelephoneMaison);
      }
      if (famille.UrgenceCellulaire) {
        famille.UrgenceCellulaire = this.formatPhoneNumber(famille.UrgenceCellulaire);
      }

      this.setState({ ...famille });
    } else {
      console.log('No matching');
    }
  }

  nextStep = () => {
    this.setState(prevState => ({ step: prevState.step + 1 }));
  };

  prevStep = () => {
    this.setState(prevState => ({ step: prevState.step - 1 }));
  };

  handleChange = (event, name) => {
    let { value } = event.target;

    if (name === 'UrgenceCellulaire' || name === 'Cellulaire' || name === 'TelephoneMaison') {
      value = this.formatPhoneNumber(value);
    }
    if (name === 'UrgenceCellulaire' || name === 'Cellulaire' || name === 'TelephoneMaison') {
      value = value.replace(/[^0-9]/g, '').slice(0, 10);
    }
    if (name === 'NoCivique' || name === 'Appartement') {
      value = value.replace(/[^0-9]/g, '').slice(0, 6);
    }
    if (name === 'CodePostal') {
      value = value.replace(/\s+/g, '').toUpperCase();
      if (value.length > 3) {
        value = value.slice(0, 3) + ' ' + value.slice(3, 6);
      }
      value = value.slice(0, 7);
    }
    if (name === 'sexe') {
      value = value === 'Masculin' ? 'M' : 'F';
    }
    this.setState({ [name]: value });
  };

  handleDateChange = ({ year, month, day }) => {
    this.setState({
      DDN_AAAA: year,
      DDN_MM: month,
      DDN_JJ: day
    });
  };

  validateFields = () => {
    const {
      step,
      prenom,
      nom,
      DDN_AAAA,
      DDN_MM,
      DDN_JJ,
      sexe,
      DejaSuiviCoursDArabe,
      LangueMaison,
      NiveauScolairePublic,
      NiveauScolaireIslamique,
      NomAncienMadrassa,
      LieuNaissance,
      NoAssuranceMaladie,
      ExpirationAssurance_AAAA,
      ExpirationAssurance_MM,
      ProblemesSante,
      NomPere,
      PrenomPere,
      NomMere,
      PrenomMere,
      TelephoneMaison,
      Cellulaire,
      UrgenceContact,
      UrgenceCellulaire,
      Ville,
      Rue,
      NoCivique,
      Appartement,
      CodePostal
    } = this.state;

    let missingFields = false;
    let errorMessage = '';

    switch (step) {
      case 1:
        if (!prenom.trim() || !nom.trim() || !LieuNaissance.trim()) {
          missingFields = true;
          errorMessage = 'Veuillez remplir tous les champs.';
        }
        break;
      case 3:
        if (!DejaSuiviCoursDArabe.trim() || !LangueMaison.trim() || !NiveauScolairePublic.trim() || !NiveauScolaireIslamique.trim()) {
          missingFields = true;
          errorMessage = 'Veuillez remplir tous les champs.';
        }
        break;
      case 4:
        if (!NomPere.trim() || !PrenomPere.trim() || !NomMere.trim() || !PrenomMere.trim() || !UrgenceContact.trim()) {
          missingFields = true;
          errorMessage = 'Veuillez remplir tous les champs.';
        }
        break;
      case 5:
        if (!PrenomMere.trim() || !TelephoneMaison.trim() || !Cellulaire.trim() || !UrgenceCellulaire.trim()) {
          missingFields = true;
          errorMessage = 'Veuillez remplir tous les champs.';
        }
        break;
      case 6:
        if (!Ville.trim() || !Rue.trim() || !CodePostal.trim()) {
          missingFields = true;
          errorMessage = 'Veuillez remplir tous les champs, excepté Appartement si non applicable.';
        }
        break;
      default:
        break;
    }

    if (missingFields) {
      this.setState({ error: errorMessage });
      return false;
    }
    this.setState({ error: '' });
    return true;
  };

  nextStep = () => {
    if (this.validateFields()) {
      this.setState(prevState => ({ step: prevState.step + 1 }));
    }
  };

  handlePhoneChange = Cellulaire => {
    this.setState({ Cellulaire });
  };

  handleTelephoneMaisonChange = TelephoneMaison => {
    this.setState({ TelephoneMaison });
  };

  handleUrgenceCellulaireChange = UrgenceCellulaire => {
    this.setState({ UrgenceCellulaire });
  };

  handleDejaSuiviCoursDArabeChange = DejaSuiviCoursDArabe => {
    this.setState({ DejaSuiviCoursDArabe });
  };

  handleSubmit = async event => {
    event.preventDefault();
    if (!this.validateFields()) {
      return;
    }

    const eleveData = {
      prenom: this.state.prenom,
      nom: this.state.nom,
      DDN_AAAA: this.state.DDN_AAAA,
      DDN_MM: this.state.DDN_MM,
      DDN_JJ: this.state.DDN_JJ,
      sexe: this.state.sexe,
      DejaSuiviCoursDArabe: this.state.DejaSuiviCoursDArabe,
      LangueMaison: this.state.LangueMaison,
      NiveauScolairePublic: this.state.NiveauScolairePublic,
      NiveauScolaireIslamique: this.state.NiveauScolaireIslamique,
      NomAncienMadrassa: this.state.NomAncienMadrassa,
      LieuNaissance: this.state.LieuNaissance,
      NoAssuranceMaladie: this.state.NoAssuranceMaladie,
      ExpirationAssurance_AAAA: this.state.ExpirationAssurance_AAAA,
      ExpirationAssurance_MM: this.state.ExpirationAssurance_MM,
      ProblemesSante: this.state.ProblemesSante
    };

    try {
      const response = await base.push('/Eleves', {
        data: eleveData
      });
      this.setState({ IDUser: response.key, showSuccess: true });
      this.pushFamilyData(response.key);
    } catch (error) {
      console.error('Error adding record:', error);
      this.setState({ error: 'Failed to add record. Please try again.' });
    }
  };

  pushFamilyData = async IDUser => {
    const familyData = {
      IDUser,
      NomPere: this.state.NomPere,
      PrenomPere: this.state.PrenomPere,
      NomMere: this.state.NomMere,
      PrenomMere: this.state.PrenomMere,
      TelephoneMaison: this.state.TelephoneMaison,
      Cellulaire: this.state.Cellulaire,
      UrgenceContact: this.state.UrgenceContact,
      UrgenceCellulaire: this.state.UrgenceCellulaire,
      UrgencePhoneMaison: this.state.UrgencePhoneMaison,
      Ville: this.state.Ville,
      Rue: this.state.Rue,
      NoCivique: this.state.NoCivique,
      Appartement: this.state.Appartement,
      CodePostal: this.state.CodePostal
    };

    try {
      await base.push('/Familles', {
        data: familyData
      });
    } catch (error) {
      console.error('Error adding family record:', error);
    }
  };

  resetForNewEntry = () => {
    this.setState({
      prenom: '',
      nom: '',
      step: 1,
      showSuccess: false,
      DDN_AAAA: '',
      DDN_MM: '',
      DDN_JJ: '',
      NomAncienMadrassa: '',
      LieuNaissance: '',
      NoAssuranceMaladie: '',
      ExpirationAssurance_AAAA: '',
      ExpirationAssurance_MM: '',
      ProblemesSante: this.state.ProblemesSante,
      sexe: 'Masculin',
      DejaSuiviCoursDArabe: 'Oui',
      LangueMaison: 'Arabe',
      NiveauScolairePublic: 'Prématernelle',
      NiveauScolaireIslamique: 'Débutant'
    });
    this.props.history.push('/user/product-list');
  };

  renderStepContent() {
    const { step } = this.state;
    switch (step) {
      case 1:
        return this.renderStep1();
      case 2:
        return this.renderStep2();
      case 3:
        return this.renderStep3();
      case 4:
        return this.renderStep4();
      case 5:
        return this.renderStep5();
      case 6:
        return this.renderStep6();
      default:
        return null;
    }
  }

  renderStep1() {
    return (
      <Fragment>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Nom complet de l'élève"
              placeholder="Prénom"
              value={this.state.prenom}
              handleChange={e => this.handleChange(e, 'prenom')}
              hideTitleOnMobile={false}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder="Nom"
              value={this.state.nom}
              handleChange={e => this.handleChange(e, 'nom')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            {(() => {
              let inverse = this.state.sexe === "Masculin" ? "Féminin" : "Masculin";
              return (
                <InputDropDown
                  title="Sexe"
                  options={[this.state.sexe, inverse]}
                  value={this.state.sexe}
                  handleChange={e => this.handleChange(e, 'sexe')}
                />
              );
            })()}
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            {(() => {
              
              return (
                <DateInput
                  onChange={this.handleDateChange}
                  title={this.state.sexe === 'Féminin' ? 'Née le' : 'Né le'}
                  dayPresence={true}
                  naissance={true}
                  annee={this.state.DDN_AAAA}
                  mois={this.state.DDN_MM}
                  jour={this.state.DDN_JJ}
                />
              );
            })()}
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Lieu de naissance"
              placeholder="pays de naissance"
              value={this.state.LieuNaissance}
              handleChange={e => this.handleChange(e, 'LieuNaissance')}
            />
          </FlexColumn>
        </FlexRow>
      </Fragment>
    );
  }

  renderStep2() {
    return (
      <Fragment>
        <FlexRow>
          <FlexColumn>
            <InputDropDown
              title="Problèmes de santé"
              options={['Non', 'Oui']}
              value={this.state.ProblemesSante}
              handleChange={e => this.handleChange(e, 'ProblemesSante')}
            />
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Assurance maladie"
              placeholder="Numéro d'assurance maladie"
              value={this.state.NoAssuranceMaladie}
              handleChange={e => this.handleChange(e, 'NoAssuranceMaladie')}
            />
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            <DateInputAssurance
              onChange={this.handleDateChange}
              title="Expiration"
              dayPresence={false}
              naissance={false}
            />
          </FlexColumn>
        </FlexRow>
      </Fragment>
    );
  }

  renderStep3() {
    return (
      <Fragment>
        <InputDropDown
          title="A déjà suivi des cours d'arabe?"
          options={['Oui', 'Non']}
          value={this.state.DejaSuiviCoursDArabe}
          handleChange={e => this.handleChange(e, 'DejaSuiviCoursDArabe')}
        />
        <InputDropDown
          title="Langue parlée à la maison"
          options={['Arabe', 'Kabyle', 'Francais', 'Anglais', 'Turc', 'Autres']}
          value={this.state.LangueMaison}
          handleChange={e => this.handleChange(e, 'LangueMaison')}
        />
        <InputDropDown
          title="Niveau scolaire en public"
          options={[
            'Prématernelle',
            'maternelle',
            '1re année',
            '2e année',
            '3e année',
            '4e année',
            '5e année',
            '6e année',
            '1re secondaire',
            '2e secondaire',
            '3e secondaire',
            '4e secondaire',
            '5e secondaire'
          ]}
          value={this.state.NiveauScolairePublic}
          handleChange={e => this.handleChange(e, 'NiveauScolairePublic')}
        />
        <InputDropDown
          title="Niveau scolaire islamique"
          options={['Débutant', 'Moyen', 'Avancé']}
          value={this.state.NiveauScolaireIslamique}
          handleChange={e => this.handleChange(e, 'NiveauScolaireIslamique')}
        />
        <InputText
          title="Nom de l'ancienne école de coran"
          value={this.state.NomAncienMadrassa}
          handleChange={e => this.handleChange(e, 'NomAncienMadrassa')}
        />
      </Fragment>
    );
  }

  renderStep4() {
    return (
      <Fragment>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Nom complet du père"
              placeholder="Prénom"
              value={this.state.PrenomPere}
              handleChange={e => this.handleChange(e, 'PrenomPere')}
              hideTitleOnMobile={false}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder="Nom"
              value={this.state.NomPere}
              handleChange={e => this.handleChange(e, 'NomPere')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Nom complet de la mère"
              placeholder="Prénom"
              value={this.state.PrenomMere}
              handleChange={e => this.handleChange(e, 'PrenomMere')}
              hideTitleOnMobile={false}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder="Nom"
              value={this.state.NomMere}
              handleChange={e => this.handleChange(e, 'NomMere')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Contact d'urgence"
              placeholder="Nom complet du contact "
              value={this.state.UrgenceContact}
              handleChange={e => this.handleChange(e, 'UrgenceContact')}
            />
          </FlexColumn>
        </FlexRow>
      </Fragment>
    );
  }

  renderStep5() {
    return (
      <Fragment>
        <FlexRow>
          <FlexColumn>
            <CustomPhoneInput
              value={this.state.Cellulaire}
              onChange={this.handlePhoneChange}
              text="Cellulaire"
            />
          </FlexColumn>
          <FlexColumn>
            <CustomPhoneInput
              value={this.state.TelephoneMaison}
              onChange={this.handleTelephoneMaisonChange}
              text="Téléphone de la Maison"
            />
          </FlexColumn>
          <FlexColumn>
            <CustomPhoneInput
              value={this.state.UrgenceCellulaire}
              onChange={this.handleUrgenceCellulaireChange}
              text="Cellulaire d'urgence"
            />
          </FlexColumn>
        </FlexRow>
      </Fragment>
    );
  }

  renderStep6() {
    return (
      <Fragment>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="Adresse"
              placeholder="Numéro civique"
              value={this.state.NoCivique}
              handleChange={e => this.handleChange(e, 'NoCivique')}
              hideTitleOnMobile={false}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder="Numéro d'appartement"
              value={this.state.Appartement}
              handleChange={e => this.handleChange(e, 'Appartement')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder="Rue"
              value={this.state.Rue}
              handleChange={e => this.handleChange(e, 'Rue')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder="CodePostal"
              value={this.state.CodePostal}
              handleChange={e => this.handleChange(e, 'CodePostal')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
          <FlexColumn>
            <InputText
              title="&#160;"
              placeholder="Ville"
              value={this.state.Ville}
              handleChange={e => this.handleChange(e, 'Ville')}
              hideTitleOnMobile={true}
            />
          </FlexColumn>
        </FlexRow>
      </Fragment>
    );
  }

  render() {
    const { step, showSuccess, error } = this.state;

    return (
      <Wrapper2>
        {this.state.showBox && (
          <Info
            visible={this.state.showBox}
            title="Assurez-vous que tous les détails suivants sont corrects."
            okText="Ok"
            onConfirm={() => this.setState({ showBox: false })}
          />
        )}
        {error && <Message>{error}</Message>}
        {this.renderStepContent()}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          {step > 1 && <SubmitButton onClick={this.prevStep}>Précédent</SubmitButton>}
          {step < 6 ? (
            <SubmitButton onClick={this.nextStep} style={{ marginLeft: 'auto' }}>
              Suivant
            </SubmitButton>
          ) : (
            <SubmitButton onClick={this.handleSubmit}>Soumettre</SubmitButton>
          )}
        </div>
        {this.state.showSuccess && (
          <Info
            visible={this.state.showSuccess}
            title="C'est bon ✔ merci pour l'update."
            okText="Ok"
            onConfirm={() => {
              this.setState({ showSuccess: false });
              this.props.history.push('/user/product-list');
            }}
          />
        )}
      </Wrapper2>
    );
  }
}

export default EditProduct;
