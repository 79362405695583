import React from 'react';
import styled from 'styled-components';

const Subcategory = styled.div`
  display: block;
  text-transform: capitalize;
  cursor: pointer;
`;

const Icon = styled.i`
  font-size: 16px;
  margin-right: 10px;
`;

// Modified function to add safety and debugging
const modalAndSelectCategory = (props, item) => {
  if (typeof props.handleCategory !== 'function') {
    console.error("handleCategory is not a function");
    return;  // Prevent the code from running further
  }

  const fullCategory = `${item}`;
  props.handleCategory(props.categoryTitle + "_" + fullCategory);
  props.handleModal();
};


const FilterSubcategory = props => (
  props.subcategory.map((item, index) => (
    <Subcategory
      key={index}
      onClick={() => modalAndSelectCategory(props, item)}
    >
      <Icon className="ion-md-arrow-dropright" /> {item}
    </Subcategory>
  ))
);


export default FilterSubcategory;
