

import firebase from 'firebase';
import Rebase from 're-base';

const key = {
  apiKey: "AIzaSyAv1pUSJNWuWqGkosyYnzWwPSI1Dms42BE",
  authDomain: "sahaba-school.firebaseapp.com",
  projectId: "sahaba-school",
  storageBucket: "sahaba-school.appspot.com",
  messagingSenderId: "136510009831",
  appId: "1:136510009831:web:b782c42b4ebb82937c0d42",
  measurementId: "G-3RXG2V3Z4M",
  databaseURL: "https://sahaba-school-default-rtdb.firebaseio.com/"

  
};

const app = firebase.initializeApp(key);

const base = Rebase.createClass(app.database());

export default base;