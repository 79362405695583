import React from 'react';
import styled from 'styled-components';
import Modal from '../../components/utility/Modal';
import PostAdButton from '../../components/public/AuthBox/PostAdButton';
import Success from '../../components/utility/Success';
import { withRouter } from 'react-router-dom';


import SmallPhoneInput from './PhoneInput';
import 'react-phone-number-input/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import * as color from '../../color';
import InputText from '../../components/user/Form/InputText';
import Lottie from 'react-lottie';
import animationData from './Loading.json';


const LinkIcon = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorA};
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s;
  margin-left: 'auto'
  font-size: 32px;

  &:hover {
    color: ${color.colorA};
  }

  @media (max-width: 700px) {
    margin: 4px;
    font-size: 24px;
  }
`;

const HeaderBar = styled.div`
  display: flex;
  flex-direction: column;  // Empiler les éléments verticalement sur mobile
  align-items: center;  // Centrer les éléments
  width: 100%;  // Utiliser toute la largeur disponible
  padding: 0 10px;  // Ajuster le padding pour les petits écrans

  @media (min-width: 768px) {
    flex-direction: row;  // Aligner horizontalement sur les écrans plus grands
    justify-content: space-between;
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const LottieAnimation = styled(Lottie)`
  width: 60%;  // Taille appropriée pour l'animation sur mobile
  margin: 10px auto;  // Centrer verticalement et horizontalement
`;


class AuthBox extends React.Component {
  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  state = {
    modal: false,
    showAuthFail: false,
    phoneNumber: '',
    otp: '',
    confirmResult: null,
    step: 1,
    loading: false,
    badOTP: false,
    retry: false
  };

  componentDidMount() {

  }



  componentWillUnmount() {

  }

  handleModal = () => {
    this.setState({ modal: !this.state.modal });
  }

  handlePhoneChange = (phoneNumber) => {
    this.setState({ phoneNumber });
  };

  handleOTPChange = (e) => {
    this.setState({ otp: e.target.value });
  };

  normalizePhoneNumber = (number) => {
    const digits = number.replace(/\D/g, '');  // Remove non-digit characters
    // Check if the number already starts with '1' and only add '+1' if it doesn't
    return digits.startsWith('1') ? `+${digits}` : `+1${digits}`;
}

  sendOTP = () => {
    const { phoneNumber } = this.state;
    const { familias } = this.props;
    // Check if the phone number exists in the familias array
    const phoneExists = familias.some(familia => [
      this.normalizePhoneNumber(familia.TelephoneMaison), 
      this.normalizePhoneNumber(familia.UrgenceCellulaire), 
      this.normalizePhoneNumber(familia.UrgenceContact), 
      this.normalizePhoneNumber(familia.UrgencePhoneMaison), 
      this.normalizePhoneNumber(familia.Cellulaire)
    ].includes(phoneNumber));

    if (!phoneExists) {
      // Handle the case where the phone number is not found
      console.error('Phone number does not exist in the database');
      this.setState({ loading: false, showAuthFail: true, modal: false });
      return;
    }

    this.setState({ loading: true }); // Commencer l'animation de chargement
    const url = 'https://api.telnyx.com/v2/verifications/sms';
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer KEY018F414A1AD0A8CF9052E87EEDD52A09_nbB4pWmiOFqRppnTN5Ipzd'
    };
    const data = {
      phone_number: this.state.phoneNumber,
      verify_profile_id: '4900018f-407d-5320-c768-7c626fa5fc27'
    };

    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        setTimeout(() => {
          this.setState({ loading: false, step: 2 }); // Arrêter l'animation et passer à l'étape 2 après 5 secondes
        }, 7000); // 5 secondes d'attente
      })
      .catch(error => {
        console.error('Failed to send OTP:', error);
        this.setState({ loading: false, showAuthFail: true, modal: false });
      });
  };

  // In AuthBox.js
  verifyOTP = () => {
    const { otp, phoneNumber } = this.state;
    const url = `https://api.telnyx.com/v2/verifications/by_phone_number/${phoneNumber}/actions/verify`;
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer KEY018F414A1AD0A8CF9052E87EEDD52A09_nbB4pWmiOFqRppnTN5Ipzd'
    };
    const data = {
      code: otp,
      verify_profile_id: '4900018f-407d-5320-c768-7c626fa5fc27'
    };

    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (data.data && data.data.response_code === 'accepted') {
          this.setState({ modal: false, showAuthFail: false });
          const userData = { phoneNumber, uid: "uid-from-response" }; // Assume you get a UID back
          localStorage.setItem('user', JSON.stringify(userData));
          this.props.updateUser(userData);
          this.props.history.push('/user/product-list');

        } else {
          this.setState({ badOTP: true, modal: false });
        }
      })
      .catch(error => {
        console.error('Verification failed:', error);
        this.setState({ badOTP: true, modal: false });
      });
  };

  nextStep = () => {
    this.setState({ step: 2 });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showAuthFail && (
          <Success
            visible={this.state.showAuthFail}
            title="Numéro Inconnu: Ce numéro n'existe pas dans notre système. Êtes-vous sûr(e) d'être inscrit(e) avec ce numéro?"
            okText="Annuler"
            cancelText='Réessayer'
            onConfirm={() => this.setState({ showAuthFail: false, phoneNumber: '' })}
            onCancel={() => this.setState({ showAuthFail: false, modal: true, phoneNumber: '' })}
          />
        )}
        {this.state.badOTP && (

          <Success
            visible={this.state.badOTP}
            title="Le code que vous avez saisi est incorrect. Veuillez réessayer."
            okText="Annuler"
            cancelText='Réessayer'
            onConfirm={() => this.setState({ badOTP: false, retry: false, otp: '', step: 1, phoneNumber: '' })}
            onCancel={() => this.setState({ showAuthFail: false, modal: true, retry: true, otp: '' })}
          />
        )}
        <HeaderBar>
          <Spacer />
          <PostAdButton onClick={this.handleModal}>Se connecter</PostAdButton>
        </HeaderBar>
        <Modal
          close
          visible={this.state.modal}
          title="Se connecter"
          handleModal={this.handleModal}
        >
          <div>
            {this.state.loading && <LottieAnimation options={this.defaultOptions} height={120} width={120} />}
            {this.state.step === 1 && !this.state.loading && !this.state.retry ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                <SmallPhoneInput value={this.state.phoneNumber} onChange={this.handlePhoneChange} text={"Entrer votre numéro de téléphone"} />

                <LinkIcon onClick={this.sendOTP}>
                  <FontAwesomeIcon icon={faCircleArrowRight} size="3x" style={{ color: color.olimpico }}/>
                </LinkIcon>
              </div>
            ) : null}
            {(this.state.step === 2 && !this.state.loading) || this.state.retry ? (
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                <InputText
                  title="Code de vérification :"
                  placeholder='Reçu par SMS'
                  value={this.state.otp}
                  handleChange={this.handleOTPChange}
                />
                <LinkIcon onClick={this.verifyOTP}>
                  <FontAwesomeIcon icon={faCircleArrowRight} size="3x" style={{ color: color.olimpico }}/>
                </LinkIcon>
              </div>
            ) : null}

          </div>
        </Modal>
      </React.Fragment>
    );
  }

}

export default withRouter(AuthBox);
