import styled from 'styled-components';
import * as color from '../../../color';



const PostAdButton = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  @media (max-width: 768px) {
    align-self: flex-end;  // Align the button to the right on smaller screens
  }
`;


const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: white;
  padding: 10px;
  cursor: pointer;
  @media (max-width: 700px) {
    font-size: 18px;
    padding: 7px 10px;
  }
`;


export default PostAdButton;
