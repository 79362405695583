import React from 'react';
import base from '../../firebase';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import * as color from '../../color';
import Info from '../../components/utility/Info';

const StyledDeleteProduct = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorR};
  font-size: 18px;
  transition: color 0.2s;
  cursor: pointer; // Change cursor to indicate it's clickable
  @media (max-width: 700px) {
    margin: 4px;
    font-size: 18px;
  }
`;


class Tip extends React.Component {
  state = { showBox: false };



  handleVisibility = () => this.setState({ showBox: true });

  render() {
    const { pid, children, style } = this.props;
    return (
      <React.Fragment>
        <StyledDeleteProduct onClick={this.handleVisibility}>
          {children}
        </StyledDeleteProduct>
        <Info
            visible={this.state.showBox}
            title="Cet élève n'est pas approuvé, probablement en raison de problèmes de paiement. Veuillez contacter la direction pour résoudre cette situation dès que possible. Merci de votre coopération. - La Direction"
            okText="Ok"
            onConfirm={() => this.setState({ showBox: false })}
          />
      </React.Fragment>
    );
  }
}

export default withRouter(Tip);
