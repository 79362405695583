import styled from 'styled-components';

const FilterBox = styled.div`
  width: 650px; // This should be the same as your ProductList and SearchBar width
  margin: 5px auto; // This centers the box within its container
  display: flex;
  justify-content: space-between; // This ensures that the child components are spaced evenly
  text-align: center;
  

  @media (max-width: 700px) {
    flex-direction: column;
    width: 95vw; // This will take nearly the full viewport width
    margin-top: 5px; // You may want to adjust this to align with the rest of your mobile layout
    margin-left: auto;
    margin-right: auto;
  }
`;



export default FilterBox;
