import React from 'react';
import { Card, CardContent, CardHeader, Avatar, Typography } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import * as color from '../../color';

const DevoirList = ({ devoirs }) => {
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div style={{ padding: '10px', background: 'white' }}>
      {devoirs.map(devoir => (
        <Card 
          style={{ 
            marginBottom: '10px', 
            color: "grey", 
            borderTop: `0.01px solid grey`, // Thinner top border
            borderRight: `2px solid grey`, // Thicker right border
            borderBottom: `2px solid grey`, // Thicker bottom border
            borderLeft: `0.01px solid grey`, // Thicker left border
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.3)' // Add shadow for more emphasis
          }} 
          key={devoir.id}
        >
          <CardHeader
            avatar={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <NotificationsIcon style={{ marginRight: '8px', color: color.olimpico }} />
                <Typography variant="subtitle1" color="textSecondary">
                   Avis 
                </Typography>
              </div>
            }
            title=''
            subheader={formatDate(devoir.date)}
          />
          <CardContent>
            <Typography variant="body2" color="textPrimary" component="p">
              {devoir.description}
            </Typography>


          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default DevoirList;
