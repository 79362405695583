// src/ChartComponent.jsx
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const ProductChartItem = ({ femalesList, malesList }) => {
  const data = {
      labels: [
        `Absents: 12`,
        `présents: 87`,
        `non saisis: 0`,
        
        
      ],
    datasets: [
      {
        label: 'Eleves',
        data: [12, 87, 0],
        backgroundColor: [
          'rgba(128, 128, 128, 0.7)', // Gris
          'rgba(255, 255, 255, 0.7)',  // Blanc
          'rgba(0, 0, 0, 0.7)'  // noir
        ],
        borderColor: [
          'rgba(128, 128, 128, 0.7)', // Gris
          'rgba(255, 255, 255, 0.7)',  // Blanc
          'rgba(0, 0, 0, 0.7)'  // noir
        ],
        borderWidth: 1,
      },
    ],
  };


  return <Doughnut data={data} />;
};

ProductChartItem.propTypes = {
  malesList: PropTypes.number.isRequired,
  femalesList: PropTypes.number.isRequired,
};

export default ProductChartItem;
