import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import React from 'react';
import * as color from '../../color';

// Styled wrapper
const StyledPhoneInput = styled.div`
  .PhoneInputInput {
    display: block;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    color: ${color.colorBlack}; // Utilisez votre palette de couleurs
    border: 1px solid ${color.colorBlack};  // Utilisez votre palette de couleurs
    padding: 8px; 
    margin-bottom: 10px; 

    @media (max-width: 700px) {
      width: 100%;
      font-size: 14px;
    }

    &:focus {
      outline: none;
      color: black;
      background-color: white;  // Utilisez votre palette de couleurs
    }
  }
`;



export default function SmallPhoneInput({ value, onChange, text }) {
  return (
    <StyledPhoneInput>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="phone-input" style={{ display: 'block', marginBottom: '5px', fontSize: '18px', color: 'black' }}>{text}</label>
        <PhoneInput
          international
          defaultCountry="CA"
          value={value}
          onChange={onChange}
          limitMaxLength
          labels={{ CA: 'Canada (+1)' }}
          countryCallingCodeEditable={false}
        />
      </div>
    </StyledPhoneInput>
  );
}
