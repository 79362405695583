import React, { useState } from 'react';
import Modal from '../../components/utility/Modal';
import styled from 'styled-components';
import * as color from '../../color';
import Lottie from 'react-lottie';
import animationData from '../public/Loading.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const LinkIcon = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorA};
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s;
  font-size: 32px;
  &:hover {
    color: ${color.colorA};
  }
  @media (max-width: 700px) {
    margin: 4px;
    font-size: 24px;
  }
`;

const Wrapper2 = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: White;
  border-radius: 5px;
  max-width: 650px;
  width: 100%;
  margin: 20px auto;
  overflow: visible;
  @media (max-width: 768px) {
    padding: 15px;
    width: 95vw;
    margin: 15px auto;
  }
`;

const StyledDeleteProduct = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorR};
  font-size: 18px;
  transition: color 0.2s;
  cursor: pointer;
  @media (max-width: 700px) {
    margin: 4px;
    font-size: 18px;
  }
`;

const LottieAnimation = styled(Lottie)`
  width: 60%;
  margin: 10px auto;
`;

const InputTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const Message = ({ isOpen, onRequestClose, handleSend, children, phoneNumbers }) => {
  const [message, setMessage] = useState('');

  const handleMessageChange = event => {
    const value = event.target.value;
    if (value.length <= 1500) {
      setMessage(value);
    }
  };

  const sendMessage = (phoneNumbers, message) => {
    const url = 'https://api.telnyx.com/v2/messages';
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer KEY018F414A1AD0A8CF9052E87EEDD52A09_nbB4pWmiOFqRppnTN5Ipzd'
    };

    phoneNumbers.forEach(phoneNumber => {
      const data = {
        from: "+15622715983",
        to: phoneNumber,
        text: message,
        media_urls: []
      };

      fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          console.log(`Message sent to ${phoneNumber}:`, data);
        })
        .catch(error => {
          console.error(`Failed to send message to ${phoneNumber}:`, error);
        });
    });
  };

  const handleSendClick = () => {
    sendMessage(phoneNumbers, message);
    //const phoneTest = ["+15145770596", "+14384023686"]
    //sendMessage(phoneTest, message);
    onRequestClose();
  };

  return (
    <React.Fragment>
      <StyledDeleteProduct onClick={() => setMessage('')}>
        {children}
      </StyledDeleteProduct>
      <Modal
        close
        visible={isOpen}
        title="Message"
        handleModal={onRequestClose}
      >
        <Wrapper2>
          <InputTextarea
            value={message}
            onChange={handleMessageChange}
            placeholder="Type your message here..."
          />
          <LinkIcon onClick={handleSendClick}>
            <FontAwesomeIcon icon={faCircleArrowRight} size="2x" style={{ color: color.olimpico }} />
          </LinkIcon>
        </Wrapper2>
      </Modal>
    </React.Fragment>
  );
};

Message.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleSend: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  phoneNumbers: PropTypes.array.isRequired,
  children: PropTypes.node
};

export default Message;
