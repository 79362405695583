import React, { useState, useEffect } from 'react';
import base from '../../firebase';
import Modal from '../../components/utility/Modal';
import styled from 'styled-components';
import * as color from '../../color';
import Lottie from 'react-lottie';
import animationData from '../../containers/public/Loading.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import InputTextarea from '../../components/user/Form/InputTextarea';
import PropTypes from 'prop-types';

const LinkIcon = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorA};
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s;
  font-size: 32px;
  &:hover {
    color: ${color.colorA};
  }
  @media (max-width: 700px) {
    margin: 4px;
    font-size: 24px;
  }
`;

const Wrapper2 = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: White;
  border-radius: 5px;
  max-width: 650px;
  width: 100%;
  margin: 20px auto;
  overflow: visible;
  @media (max-width: 768px) {
    padding: 15px;
    width: 95vw;
    margin: 15px auto;
  }
`;

const StyledDeleteProduct = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorR};
  font-size: 18px;
  transition: color 0.2s;
  cursor: pointer;
  @media (max-width: 700px) {
    margin: 4px;
    font-size: 18px;
  }
`;

const LottieAnimation = styled(Lottie)`
  width: 60%;
  margin: 10px auto;
`;

const Notification = ({ visible, pid, children, onClose, handleBadge }) => {
  const [state, setState] = useState({
    visible: false,
    description: '',
    loading: false,
    before: ''
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    if (visible) {
      setState({ visible: true, loading: true });
      fetchDescriptionDetails();
    }
  }, [visible]);

  const handleConfirm = () => {
    base.update(`/Eleves/${pid}`, {
      data: {
        description: state.description
      }
    })
    .then(() => {
      setState(prevState => ({ ...prevState, visible: false }));
      onClose();
      console.log("before ", state.before);
      console.log("after ", state.description);
      if (state.description && !state.before) {
        handleBadge(1);
      } else if (!state.description && state.before) {
        handleBadge(-1);
      }
    })
    .catch(error => console.log(error));
  };

  const fetchDescriptionDetails = () => {
    base.fetch(`/Eleves/${pid}`, {
      context: this,
      then(data) {
        setState({
          description: data.description,
          before: data.description,
          loading: false
        });
        
      }
    }).catch(error => {
      console.error("Error fetching data: ", error);
      setState({ loading: false });
    });
  };

  const handleDescriptionChange = event => {
    const value = event.target.value;
    if (value.length <= 1500) {
      setState(prevState => ({ ...prevState, description: value }));
    }
  };

  const handleModal = () => {
    setState(prevState => ({ ...prevState, visible: !prevState.visible }));
    onClose();
  };

  return (
    <React.Fragment>
      <StyledDeleteProduct onClick={() => setState(prevState => ({ ...prevState, visible: true }))}>
        {children}
      </StyledDeleteProduct>
      <Modal
        close
        visible={visible}
        title="Notes"
        handleModal={handleModal}
      >
        <Wrapper2>
          {state.loading && <LottieAnimation options={defaultOptions} height={120} width={120} />}
          {!state.loading && (
            <React.Fragment>
              <InputTextarea
                title=""
                placeholder=""
                value={state.description}
                handleChange={handleDescriptionChange}
              />
              <LinkIcon onClick={handleConfirm}>
                <FontAwesomeIcon icon={faCircleArrowRight} size="2x" style={{ color: color.olimpico }}/>
              </LinkIcon>
            </React.Fragment>
          )}
        </Wrapper2>
      </Modal>
    </React.Fragment>
  );
};

Notification.propTypes = {
  visible: PropTypes.bool.isRequired,
  pid: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  handleBadge: PropTypes.func.isRequired
};

export default Notification;
