import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as color from '../../color';

const Focus = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  position: fixed; // Updated from absolute to fixed
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; // This centers the content vertically
  z-index: 99;
`;


const Content = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  width: auto; // Or set a specific width if you need
  max-width: 500px; // Optional, if you want to limit how wide the modal can get
  // Removed position, top, left, and transform properties
  overflow: auto;
  z-index: 100;
`;

const Title = styled.div`
  font-size: 16px;
  text-align: center;
  color: ${color.colorA};
`;

const Body = styled.div`
  font-size: 16px;
  margin: 10px 5px;
  display: flex;
  justify-content: space-around; /* Ou space-between pour un espacement encore plus grand */
`;

const Button = styled.div`
  text-decoration: none;
  border-style: none;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px; /* Ajoutez une marge horizontale pour espacer les boutons */
`;

const OkButton = styled(Button)`
  background-color: ${color.colorA};
`;

const CancelButton = styled(Button)`
  background-color: ${color.colorA};
`;



const Confirm = props => {
  return props.visible ? (
    <React.Fragment>
      <Focus>
        <Content>
          <Title>{props.title}</Title>
          <Body>
            <OkButton onClick={props.onConfirm}>{props.okText}</OkButton>
            <CancelButton onClick={props.onCancel}>
              {props.cancelText}
            </CancelButton>
          </Body>
        </Content>
      </Focus>
    </React.Fragment>
  ) : null;
};

Confirm.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
export default Confirm;
