import React, { useState, useEffect } from 'react';
import { Collapse, Typography, CardContent, Grid, CardActions, CardHeader, Card, IconButton, Avatar, Menu, MenuItem, Badge, styled } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LabelOff from '@material-ui/icons/SupervisorAccountRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WhatsApp from '@material-ui/icons/WhatsApp';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import NotificationsIcon from '@material-ui/icons/Notifications';
import InsertComment from '@material-ui/icons/InsertComment';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as color from '../../../color';
import styled2 from 'styled-components';
import PropTypes from 'prop-types';
import NotificationConsult from '../../../containers/user/NotificationConsult';
import Class from '../../../containers/user/Class';
import Approve from '../../../containers/user/Approve';
import Desapprove from '../../../containers/user/Desapprove';
import base from '../../../firebase';
import Confirm from '../../utility/Confirm';
import Notification from '../../../containers/user/Notification';
import { Link } from 'react-router-dom';

const ExternalLinkIcon = styled2.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: color 0.2s;
  text-decoration: none;
  color: ${props => props.color || 'inherit'};
  @media (max-width: 700px) {
    font-size: 16px;
  }
`;

const LinkIcon = styled2(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color || 'inherit'};
  font-size: 16px;
  transition: color 0.2s;
  text-decoration: none;
  @media (max-width: 700px) {
    font-size: 16px;
  }
`;

const CardWrapper = styled2.div`
  width: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0;

  @media (max-width: 700px) {
    margin: 8px;
    font-size: 24px;
    width: 95vw;
  }
`;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

RecipeClassCard.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  famille: PropTypes.object.isRequired,
  badgeNumber: PropTypes.number.isRequired,
};

export default function RecipeClassCard({ id, item, user, famille, badgeNumber }) {
  const { nom, prenom, sexe, DDN_AAAA, DDN_JJ, DDN_MM, DateInscription, Niveau, LangueMaison, DejaSuiviCoursDArabe, LieuNaissance, NiveauScolaireIslamique, NiveauScolairePublic, NomAncienMadrassa, ProblemesSante } = item;
  const { IDUser, TelephoneMaison, UrgenceCellulaire, UrgencePhoneMaison } = famille;

  const title = `${prenom} ${nom}`;
  const Cred = `${nom.charAt(0).toUpperCase()}${prenom.charAt(0).toUpperCase()}`;
  const [expanded, setExpanded] = useState(false);
  const [Approved, setApproved] = useState(item.Approved);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notesVisible, setNotesVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [badge, setBadge] = useState(0);

  const sexeColor = sexe === 'M' ? "#87CEFA" : "#FFB6C1";
  const isStudent = [normalizePhoneNumber(TelephoneMaison), normalizePhoneNumber(UrgenceCellulaire), normalizePhoneNumber(UrgencePhoneMaison)].includes(normalizePhoneNumber(user.phoneNumber));
  const isAdmin = ["+15146532079", "+15145770596"].includes(user.phoneNumber);
  const isRestricted = ["+15144629373", "+14384101920", "+15146913866", "+15142613284", "+15145527060", "+14382828612", "+14382201778"].includes(user.phoneNumber);

  useEffect(() => {
    fetchDescriptionDetails();
  }, []);

  function fetchDescriptionDetails() {
    base.fetch(`/Eleves/${id}`, {
      context: this,
      then(data) {
        if (data.description) {
          handleBadge(1);
        }
      }
    }).catch(error => {
      console.error("Error fetching data: ", error);
    });
  }

  if (!isStudent && !isAdmin && !isRestricted) return null;

  const currentYear = new Date().getFullYear();
  const age = calculateAge(DDN_AAAA, DDN_MM, DDN_JJ);
  const inscrit = sexe === 'M' ? "inscrit pour: " : "inscrite pour: ";
  const inscriptionYear = new Date(DateInscription).getFullYear();
  const anneeScolaire = `${inscriptionYear}/${inscriptionYear + 1}`;
  const subheader = Niveau ? Niveau : 'Pas encore assigné';
  const SonNiveau = Niveau ? Niveau : 'Pas encore assigné';
  const Colored = !!Niveau;

  const handleApprovedChange = () => setApproved('Yes');
  const handleDesapprovedChange = () => setApproved('No');
  const handleExpandClick = () => setExpanded(!expanded);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuRetrait = () => {
    setDeleteVisible(true);
    setAnchorEl(null);
  };

  const handleBadge = (b) => {
    setBadge(prevBadge => prevBadge + b);
    console.log("totalBadge", badge + b);
  };

  const handleDeleteConfirm = async () => {
    try {
      await base.remove(`/Eleves/${id}`);
      setDeleteVisible(false);
      setAnchorEl(null);
    } catch (error) {
      console.error('Failed to remove product:', error);
    }
  };

  const handleDeleteCancel = () => setDeleteVisible(false);
  const handleNotesOpen = () => {
    setNotesVisible(true);
    setAnchorEl(null);
  };
  const handleNotesClose = () => setNotesVisible(false);

  function normalizePhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';
    const cleaned = phoneNumber.replace(/\D/g, '');
    return cleaned.length === 10 ? `+1${cleaned}` : `+${cleaned}`;
  }

  function calculateAge(ddn_aaaa, ddn_mm, ddn_jj) {
    const birthDate = new Date(ddn_aaaa, ddn_mm - 1, ddn_jj);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <CardWrapper>
      <Card style={{ width: '100%' }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} style={{ backgroundColor: sexeColor }} aria-label="recipe">
              {Cred}
            </Avatar>
          }
          action={
            <div>
              <IconButton aria-label="settings" onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>

              <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {(isAdmin || isRestricted) && (
                  <MenuItem onClick={handleNotesOpen}>
                    <InsertComment style={{ marginRight: 8, color: color.olimpico }} /> Notes
                  </MenuItem>
                )}

                {(isAdmin || isRestricted) && (
                  <MenuItem>
                    <ExternalLinkIcon href={`https://wa.me/+1${TelephoneMaison.replace(/\D/g, '')}`} target="_blank" rel="noopener noreferrer">
                      <WhatsApp style={{ marginRight: 8, color: color.olimpico }} /> Contact
                    </ExternalLinkIcon>
                  </MenuItem>
                )}

                


              </Menu>

              <Notification pid={id} visible={notesVisible} onClose={handleNotesClose} handleBadge={handleBadge} />
            </div>
          }
          title={title}
          subheader={subheader}
        />

        <CardActions disableSpacing style={{ justifyContent: 'flex-start' }}>
          {(isAdmin || isRestricted) && (
            <IconButton aria-label="assign" style={{ marginRight: 8 }}>
              {Colored ? (
                <Class pid={id}>
                  <AssignmentIcon style={{ color: color.olimpico }} />
                </Class>
              ) : (
                <Class pid={id}>
                  <AssignmentIcon style={{ color: 'grey' }} />
                </Class>
              )}
            </IconButton>
          )}

{(isAdmin || isRestricted) && (
            <IconButton aria-label="problem" style={{ marginRight: 8 }}>
              {Approved === "Yes" ? (
                <Desapprove pid={id} onDesapprovedChange={handleDesapprovedChange}>
                  <CheckCircleIcon style={{ color: color.olimpico }} />
                </Desapprove>
              ) : (
                <Approve pid={id} onApprovedChange={handleApprovedChange}>
                  <ReportProblemIcon style={{ color: 'grey' }} />
                </Approve>
              )}
            </IconButton>
          )}


          <IconButton aria-label="notifications" style={{ marginRight: 8 }}>
            <NotificationConsult pid={id} handleBadge={handleBadge}>
              <Badge badgeContent={badge + badgeNumber} color="primary">
                <NotificationsIcon color="action" />
              </Badge>
            </NotificationConsult>
          </IconButton>

          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
          <CardContent style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Langue parlée à la maison:<strong> {LangueMaison}</strong>
                </Typography>
                <Typography variant="body2">
                  A-t-il déjà suivi des cours en arabe:<strong> {DejaSuiviCoursDArabe}</strong>
                </Typography>
                <Typography variant="body2">
                  Lieu de Naissance:<strong> {LieuNaissance}</strong>
                </Typography>
                <Typography variant="body2">
                  Son niveau Scolaire Islamique:<strong> {NiveauScolaireIslamique}</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Niveau scolaire en public:<strong> {NiveauScolairePublic}</strong>
                </Typography>
                <Typography variant="body2">
                  Nom de l'ancienne Madrassa:<strong> {NomAncienMadrassa}</strong>
                </Typography>
                <Typography variant="body2">
                  Problèmes de Santé:<strong> {ProblemesSante}</strong>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </CardWrapper>
  );
}
