import React from 'react';
import PropTypes from 'prop-types';
import Title from './Title';
import styled from 'styled-components';
import * as color from '../../../color';

const Input = styled.input`
  display: block;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  color: ${color.colorBlack};
  border: 1px solid ${color.colorA};
  padding: 8px;
  @media (max-width: 700px) {
    width: 100%;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 0px;
  }

  &:focus {
    outline: none;
    color: white;
    background-color: ${color.colorA};
  }
`;



const InputText = ({ title, placeholder, value, handleChange, hideTitleOnMobile }) => (
  <Title title={title} hideOnMobile={hideTitleOnMobile}>
    <Input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
    />
  </Title>
);

InputText.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  hideTitleOnMobile: PropTypes.bool
};

export default InputText;
