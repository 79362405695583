import React from 'react';
import styled from 'styled-components';
import * as color from '../../color';

const LoadMore = styled.i`
  display: block;
  text-decoration: none;
  font-size: 25px; // Adjust based on screen size below
  padding: 10px 0; // Give more vertical padding for easier tapping
  line-height: 1; // Adjust line height for better text alignment
  color: white;
  border: none;
  border-radius: 100px;
  width: 90%; // Use percentage to adapt to various screen widths
  max-width: 650px; // Ensure it doesn't get too wide on large screens
  margin: 20px auto; // Center it horizontally with automatic margins
  background-color: ${color.colorA};
  cursor: pointer;
  text-align: center;

  @media (max-width: 700px) {
    font-size: 20px; // Smaller font size on smaller screens
    padding: 15px 0; // Increase padding for easier interaction on touch devices
  }

  @media (max-width: 400px) {
    font-size: 18px; // Even smaller font size for very small screens
  }
`;




const LoadMoreProduct = props => (
  <LoadMore onClick={props.loadMore} className="ion-ios-arrow-down" />
);

export default LoadMoreProduct;
