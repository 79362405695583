import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

import { Route, Switch, withRouter } from 'react-router-dom';

import Header from './containers/Header';
import Public from './App/Public';
import User from './App/User';
import ErrorMessage from './components/ErrorMessage';
import Body from './components/Body';
import ProductView from './containers/public/ProductView';
import AddProduct from './containers/public/AddProduct';

class App extends Component {
  state = {
    user: null
  };

  componentDidMount() {
    const userData = localStorage.getItem('user');
    if (userData) {
      this.setState({ user: JSON.parse(userData) });
    }

  }

  handleLogoutSuccess = () => {
    this.setState({ user: null });
  };

  handleUserLogin = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    this.setState({ user: userData }, () => {
      this.props.history.push('/user/product-list'); // Redirect after state is updated
    });

  };

  render() {
    return (
      <React.Fragment>
        <Body>
          {/* Routes and Switches for page navigation */}
          <Route
            path="/"
            component={props => <Header {...props} user={this.state.user} updateUser={(user) => this.setState({ user })} logoutSuccess={this.handleLogoutSuccess} />}
          />
          <Switch>
            <Route path="/" exact component={props => <Public {...props} user={this.state.user} updateUser={this.handleUserLogin} />} />
            <Route
              path="/user"
              component={props => <User {...props} user={this.state.user} />}
            />
            <Route path="/user/product-list" component={props => <User {...props} user={this.state.user} />} />
            <Route path="/public/add" component={AddProduct} />
            <Route exact path="/view/:id" render={props => <ProductView {...props} user={this.state.user} />} />
            <Route component={props => <ErrorMessage {...props}>Page not found</ErrorMessage>} />
          </Switch>

        </Body>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
