import React from 'react';
import styled from 'styled-components';

import StyledLink from '../components/utility/StyledLink';
import * as color from '../color';
import Home from '@material-ui/icons/Home';

const Header = styled.div`
  width: 100%; // Always take full width
  min-width: 650px; // Minimum width for larger screens
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${color.colorA};
  margin: 0 auto; // Center the header
  margin-bottom: 7px;
  padding: 0 10px; // Add horizontal padding if necessary for alignment

  @media (max-width: 700px) {
    min-width: 100%; // Ensure full width on small screens
    padding: 0; // Adjust padding for small screens if needed
  }
`;


const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: white;
  padding: 10px;
  cursor: pointer;
  color: ${color.olimpico};
  @media (max-width: 700px) {
    font-size: 18px;
    padding: 7px 10px;
  }
`;

const HeaderBar = (props) => (
  <Header>
    <StyledLink to="/">
      <Home style={{ fontSize: 47 , color: color.olimpico , marginLeft: 9 , marginTop: 5 }}  /> 
    </StyledLink>
    {props.children}
  </Header>
);

export default HeaderBar;
