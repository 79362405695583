import React from 'react';
import base from '../../firebase';
import { withRouter } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/utility/Modal';
import styled from 'styled-components';
import * as color from '../../color';
import Lottie from 'react-lottie';
import animationData from '../../containers/public/Loading.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import InputDropDown from '../../components/user/Form/InputDropDown';

const LinkIcon = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorA};
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s;
  margin-left: 'auto';
  margin-top: 15px;
  font-size: 16px;

  &:hover {
    color: ${color.colorA};
  }

  @media (max-width: 700px) {
    margin: 4px;
    font-size: 24px;
  }
`;

const Wrapper2 = styled.div`
  padding: 20px; // Uniform padding around all sides
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: White;
  border-radius: 5px;
  max-width: 650px;
  width: 100%;
  margin: 20px auto; // Adds margin on the top and bottom as well
  overflow: visible;

  @media (max-width: 768px) {
    padding: 15px; // Slightly less padding on mobile for more space efficiency
    width: 95vw; // Adjusts width to be responsive
    margin: 15px auto; // Less margin on mobile
  }
`;

const StyledDeleteProduct = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
  color: ${color.colorR};
  font-size: 18px;
  transition: color 0.2s;
  cursor: pointer; // Change cursor to indicate it's clickable
  @media (max-width: 700px) {
    margin: 4px;
    font-size: 18px;
  }
`;

const LottieAnimation = styled(Lottie)`
  width: 60%;  // Taille appropriée pour l'animation sur mobile
  margin: 10px auto;  // Centrer verticalement et horizontalement
`;

class Class extends React.Component {
  state = {
    visible: false, Niveau: '', loading: ''
  };
  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };




  handleConfirm = pid => {
    base.update(`/Eleves/${pid}`, {
      data: {
        Niveau: this.state.Niveau
      }
    })
      .then(() => {
        this.setState({ visible: false }); // Fermer le popup immédiatement après la mise à jour
      })
      .catch(error => console.log(error));
  };

  fetchNiveauDetails = pid => {
    base.fetch(`/Eleves/${pid}`, {
      context: this,
      then(data) {
        this.setState({
          Niveau: data.Niveau,
          loading: false // Assume you want to stop loading once data is fetched
        });
      }
    }).catch(error => {
      console.error("Error fetching data: ", error);
      this.setState({ loading: false });
    });
  }


  handleNiveauChange = event => {
    const value = event.target.value;
    const valueLength = value.length;
    if (valueLength <= 1500) {
      this.setState({
        Niveau: value,

      });
    }
  };

  

  handleChange = (event, name) => {
    let { value } = event.target;

    this.setState({ [name]: value });
  };

  handleModal = () => {
    this.setState({ visible: !this.state.visible });
  }

  handleCancel = () => this.setState({ visible: false });

  handleVisibility = () => {
    this.setState({ visible: true, loading: true }); // Set loading true to show loader while fetching
    this.fetchNiveauDetails(this.props.pid);
  }


  render() {
    const { pid, children, style } = this.props;
    return (
      <React.Fragment>
        <StyledDeleteProduct onClick={this.handleVisibility}>
          {children}
        </StyledDeleteProduct>
        <Modal
          close
          visible={this.state.visible}
          title="Classe"
          handleModal={this.handleModal}
        >
          <Wrapper2>
            {this.state.loading && <LottieAnimation options={this.defaultOptions} height={120} width={120} />}

            {!this.state.loading && (
              <React.Fragment>

<InputDropDown
              title="Niveau:"
              options={[
                'pré-maternelle',
                'maternelle',
                '1re année',
                '2e année',
                '3e année',
                '4e année',
                '5e année',
                '6e année',
                '7e année',
                '8e année',
                '9e année',
                'Débutants',
                'Intermédiaires',
                'Avancés'
              ]}
              value={this.state.Niveau}
              handleChange={this.handleNiveauChange}
            />

                <LinkIcon onClick={() => this.handleConfirm(this.props.pid)}>
                  <FontAwesomeIcon icon={faCircleArrowRight} size="2x" style={{ color: color.olimpico }}/>
                </LinkIcon>
              </React.Fragment>)}
          </Wrapper2>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(Class);
